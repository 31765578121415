type DatIconProps = {
    color?: string;
  }

export const DateIcon = ({ color = '#363BC4', ...props }: DatIconProps): JSX.Element => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4308 4.09987C10.5688 4.24527 10.7571 4.328 10.9546 4.328C11.0523 4.328 11.1489 4.30772 11.2388 4.26846C11.3288 4.22921 11.4101 4.17183 11.4784 4.09987C11.5467 4.02792 11.6006 3.94278 11.6373 3.84944C11.674 3.75611 11.6928 3.65624 11.6928 3.5555V1.6725C11.6928 1.46926 11.6163 1.27336 11.4784 1.12813C11.3404 0.982731 11.1521 0.9 10.9546 0.9C10.7571 0.9 10.5688 0.982731 10.4308 1.12813C10.293 1.27336 10.2164 1.46926 10.2164 1.6725V3.5555C10.2164 3.75874 10.293 3.95464 10.4308 4.09987Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.2" />
    <path d="M4.05189 4.09987C4.18988 4.24527 4.37818 4.328 4.57571 4.328C4.6734 4.328 4.77 4.30772 4.85994 4.26846C4.94987 4.22921 5.03123 4.17183 5.09952 4.09987C5.16781 4.02792 5.22171 3.94278 5.2584 3.84944C5.29509 3.75611 5.31392 3.65624 5.31392 3.5555V1.6725C5.31392 1.46926 5.23734 1.27336 5.09952 1.12813C4.96154 0.982731 4.77323 0.9 4.57571 0.9C4.37818 0.9 4.18988 0.982731 4.05189 1.12813C3.91407 1.27336 3.8375 1.46926 3.8375 1.6725V3.5555C3.8375 3.75874 3.91407 3.95464 4.05189 4.09987Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.2" />
    <path d="M3.68054 4.32781C3.3366 4.3285 3.00581 4.47273 2.76094 4.73077C2.51588 4.989 2.37706 5.3401 2.37642 5.70742C2.37642 5.70744 2.37642 5.70746 2.37642 5.70749V14.0461C2.37642 14.0462 2.37642 14.0462 2.37642 14.0462C2.37706 14.4135 2.51588 14.7646 2.76094 15.0229C3.0058 15.2809 3.33656 15.4251 3.68047 15.4258M3.68054 4.32781L3.68047 15.5258L3.68066 15.4258M3.68054 4.32781H11.8495C12.1934 4.3285 12.5242 4.47273 12.7691 4.73077C13.0141 4.98897 13.1529 5.34003 13.1536 5.70731M3.68054 4.32781L13.1536 5.70731M3.68047 15.4258C3.68054 15.4258 3.6806 15.4258 3.68066 15.4258M3.68047 15.4258L3.68066 15.4258M3.68047 15.4258L11.8493 15.4258M3.68066 15.4258H11.8493M11.8493 15.4258L11.8495 15.4258M11.8493 15.4258L11.8495 15.4258M11.8495 15.4258C12.1934 15.4251 12.5242 15.2809 12.7691 15.0229C13.0141 14.7647 13.1529 14.4136 13.1536 14.0463C13.1536 14.0463 13.1536 14.0462 13.1536 14.0461M11.8495 15.4258L13.2536 14.0463L13.1536 14.0461M13.1536 14.0461V5.70749M13.1536 14.0461V5.70749M13.1536 5.70749L13.1536 5.70731M13.1536 5.70749L13.1536 5.70731M11.8495 16.9708H11.8496C12.5886 16.9701 13.2959 16.6604 13.8167 16.1116C14.3373 15.563 14.6293 14.8201 14.63 14.0464V14.0463V5.70731V5.70722C14.6293 4.93349 14.3373 4.19067 13.8167 3.64204C13.2959 3.09324 12.5886 2.78355 11.8496 2.78281H11.8495L3.68047 2.78281L3.68037 2.78281C2.94145 2.78355 2.23412 3.09324 1.7133 3.64204C1.19265 4.19067 0.900698 4.93349 0.9 5.70722V5.70731L0.9 14.0463L0.9 14.0464C0.900698 14.8201 1.19265 15.563 1.7133 16.1116C2.23412 16.6604 2.94145 16.9701 3.68037 16.9708H3.68047H11.8495Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.2" />
    <path d="M1.63821 7.82586H13.8918C14.0893 7.82586 14.2776 7.74313 14.4156 7.59773C14.5534 7.4525 14.63 7.2566 14.63 7.05336C14.63 6.85012 14.5534 6.65422 14.4156 6.50899C14.2776 6.36359 14.0893 6.28086 13.8918 6.28086H1.63821C1.44068 6.28086 1.25238 6.36359 1.11439 6.50899C0.976571 6.65422 0.9 6.85012 0.9 7.05336C0.9 7.2566 0.976571 7.4525 1.11439 7.59773C1.25238 7.74313 1.44068 7.82586 1.63821 7.82586Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.2" />
    <path d="M5.72219 12.4225H9.80672C10.0042 12.4225 10.1925 12.3398 10.3305 12.1944C10.4684 12.0492 10.5449 11.8533 10.5449 11.65C10.5449 11.4468 10.4684 11.2509 10.3305 11.1057C10.1925 10.9603 10.0042 10.8775 9.80672 10.8775H5.72219C5.52467 10.8775 5.33636 10.9603 5.19838 11.1057C5.06056 11.2509 4.98398 11.4468 4.98398 11.65C4.98398 11.8533 5.06056 12.0492 5.19838 12.1944C5.33636 12.3398 5.52467 12.4225 5.72219 12.4225Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.2" />
    <path d="M7.23939 14.3461C7.37738 14.4915 7.56568 14.5742 7.76321 14.5742C7.96073 14.5742 8.14904 14.4915 8.28702 14.3461C8.42484 14.2008 8.50142 14.0049 8.50142 13.8017V9.4977C8.50142 9.29446 8.42484 9.09855 8.28702 8.95333C8.14904 8.80793 7.96073 8.7252 7.76321 8.7252C7.56568 8.7252 7.37738 8.80793 7.23939 8.95333C7.10157 9.09855 7.025 9.29446 7.025 9.4977V13.8017C7.025 14.0049 7.10157 14.2008 7.23939 14.3461Z" fill="#363BC4" stroke="#363BC4" strokeWidth="0.2" />
  </svg>

);
