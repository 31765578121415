export const IconHIndex100 = (): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2.81437C0 1.26004 1.26004 0 2.81437 0H9.00599C10.5603 0 11.8204 1.26004 11.8204 2.81437V9.00599C11.8204 10.5603 10.5603 11.8204 9.00599 11.8204H2.81437C1.26004 11.8204 0 10.5603 0 9.00599V2.81437Z" fill="url(#paint0_linear_2061_16741)" />
    <path d="M9.28574 5.91045C9.28574 7.77565 7.7737 9.28769 5.9085 9.28769C4.04329 9.28769 2.53125 7.77565 2.53125 5.91045C2.53125 4.04525 4.04329 2.5332 5.9085 2.5332C7.7737 2.5332 9.28574 4.04525 9.28574 5.91045Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.85165 4.22168C8.05045 4.42736 8.24496 4.62861 8.44421 4.83477C7.52899 5.75569 6.61527 6.67512 5.69718 7.59893C5.20276 7.09829 4.71124 6.6006 4.22266 6.10585C4.42133 5.90736 4.61683 5.71203 4.81633 5.51269C5.10653 5.80345 5.39945 6.09692 5.69629 6.39434C6.416 5.66886 7.13184 4.94727 7.85165 4.22168Z" fill="#5ECA21" />
    <defs>
      <linearGradient id="paint0_linear_2061_16741" x1="5.91018" y1="0" x2="5.91018" y2="11.8204" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5ECA21" />
        <stop offset="1" stopColor="#5ECA21" />
      </linearGradient>
    </defs>
  </svg>
);
