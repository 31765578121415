import styled from 'styled-components';

type HealthIndexOrangeIconProps = {
}

export const HealthIndexOrangeIcon = ({ ...props }: HealthIndexOrangeIconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 14.665415 12.685378" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(0.23222948,0.18062433)">
      <IconPath d="M 13.986478,10.314438 8.188018,0.698141 C 7.959574,0.319306 7.542858,0.083959 7.100465,0.083959 c -0.442393,0 -0.859108,0.235347 -1.08758,0.614209 l -5.798432,9.61627 c -0.236092,0.391563 -0.243077,0.882135 -0.01825,1.280269 0.224883,0.398135 0.648584,0.645464 1.105803,0.645464 h 11.596919 c 0.457219,0 0.880948,-0.247329 1.10583,-0.645491 0.224828,-0.398162 0.217842,-0.888735 -0.01828,-1.280242 z m -0.783127,0.827688 c -0.0619,0.109584 -0.178553,0.177697 -0.304426,0.177697 H 1.302006 c -0.125873,0 -0.242525,-0.06809 -0.304399,-0.177669 -0.0619,-0.109611 -0.05997,-0.244679 0.005,-0.35244 L 6.801094,1.173417 c 0.0629,-0.104283 0.177615,-0.169083 0.299402,-0.169083 0.121759,0 0.236478,0.0648 0.299373,0.169083 l 5.798433,9.616297 c 0.06502,0.107789 0.06695,0.242829 0.0051,0.352412 z" />
      <IconPath d="m 7.104745,3.870598 c -0.350093,0 -0.623321,0.187857 -0.623321,0.520887 0,1.016099 0.119524,2.476219 0.119524,3.492345 2.7e-5,0.264696 0.230597,0.375688 0.503824,0.375688 0.204921,0 0.495239,-0.110992 0.495239,-0.375688 0,-1.016098 0.119523,-2.476219 0.119523,-3.492345 0,-0.333002 -0.281759,-0.520887 -0.614789,-0.520887 z" />
      <IconPath d="m 7.113332,8.848685 c -0.375688,0 -0.657502,0.298849 -0.657502,0.657501 0,0.350093 0.281786,0.657502 0.657502,0.657502 0.350093,0 0.64897,-0.307409 0.64897,-0.657502 0,-0.358652 -0.298905,-0.657501 -0.64897,-0.657501 z" />
    </g>
  </svg>
);

const IconPath = styled.path`
  fill: #000000;
  fill-opacity: 1;
  stroke: none;
`;
