type ChipIconProps = {
  color?: string;
}

export const ChipIcon = ({ color = '#363BC4', ...props }: ChipIconProps): JSX.Element => (
  // eslint-disable-next-line react/no-unknown-property
  <svg width="15" height="15" viewChip="0 0 15 15" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.517 13.1695H3.48311C3.04503 13.1689 2.62509 12.9946 2.31533 12.6848C2.00556 12.375 1.83124 11.9551 1.83057 11.517V3.48311C1.83124 3.04503 2.00556 2.62509 2.31533 2.31533C2.62509 2.00556 3.04503 1.83124 3.48311 1.83057H11.517C11.9551 1.83124 12.375 2.00556 12.6848 2.31533C12.9946 2.62509 13.1689 3.04503 13.1695 3.48311V11.517C13.1689 11.9551 12.9946 12.375 12.6848 12.6848C12.375 12.9946 11.9551 13.1689 11.517 13.1695ZM3.48311 3.10175C3.38197 3.10175 3.28497 3.14193 3.21345 3.21345C3.14193 3.28497 3.10175 3.38197 3.10175 3.48311V11.517C3.10175 11.6181 3.14193 11.7151 3.21345 11.7867C3.28497 11.8582 3.38197 11.8984 3.48311 11.8984H11.517C11.6181 11.8984 11.7151 11.8582 11.7867 11.7867C11.8582 11.7151 11.8984 11.6181 11.8984 11.517V3.48311C11.8984 3.38197 11.8582 3.28497 11.7867 3.21345C11.7151 3.14193 11.6181 3.10175 11.517 3.10175H3.48311Z" fill={color} />
    <path d="M10.0424 10.678H4.95761C4.78904 10.678 4.62738 10.611 4.50818 10.4918C4.38899 10.3726 4.32202 10.2109 4.32202 10.0424V4.95761C4.32202 4.78904 4.38899 4.62738 4.50818 4.50818C4.62738 4.38899 4.78904 4.32202 4.95761 4.32202H10.0424C10.2109 4.32202 10.3726 4.38899 10.4918 4.50818C10.611 4.62738 10.678 4.78904 10.678 4.95761V10.0424C10.678 10.2109 10.611 10.3726 10.4918 10.4918C10.3726 10.611 10.2109 10.678 10.0424 10.678ZM5.59321 9.40677H9.40677V5.59321H5.59321V9.40677Z" fill={color} />
    <path d="M3.6102 3.10169C3.44163 3.10169 3.27997 3.03473 3.16077 2.91553C3.04157 2.79634 2.97461 2.63467 2.97461 2.4661V0.635593C2.97461 0.467023 3.04157 0.305358 3.16077 0.186161C3.27997 0.0669641 3.44163 0 3.6102 0C3.77877 0 3.94044 0.0669641 4.05963 0.186161C4.17883 0.305358 4.2458 0.467023 4.2458 0.635593V2.4661C4.2458 2.63467 4.17883 2.79634 4.05963 2.91553C3.94044 3.03473 3.77877 3.10169 3.6102 3.10169Z" fill={color} />
    <path d="M6.20334 3.10169C6.03477 3.10169 5.87311 3.03473 5.75391 2.91553C5.63471 2.79634 5.56775 2.63467 5.56775 2.4661V0.635593C5.56775 0.467023 5.63471 0.305358 5.75391 0.186161C5.87311 0.0669641 6.03477 0 6.20334 0C6.37191 0 6.53358 0.0669641 6.65277 0.186161C6.77197 0.305358 6.83894 0.467023 6.83894 0.635593V2.4661C6.83894 2.63467 6.77197 2.79634 6.65277 2.91553C6.53358 3.03473 6.37191 3.10169 6.20334 3.10169Z" fill={color} />
    <path d="M8.7966 3.10169C8.62803 3.10169 8.46637 3.03473 8.34717 2.91553C8.22798 2.79634 8.16101 2.63467 8.16101 2.4661V0.635593C8.16101 0.467023 8.22798 0.305358 8.34717 0.186161C8.46637 0.0669641 8.62803 0 8.7966 0C8.96517 0 9.12684 0.0669641 9.24604 0.186161C9.36523 0.305358 9.4322 0.467023 9.4322 0.635593V2.4661C9.4322 2.63467 9.36523 2.79634 9.24604 2.91553C9.12684 3.03473 8.96517 3.10169 8.7966 3.10169Z" fill={color} />
    <path d="M11.3899 3.10169C11.2213 3.10169 11.0596 3.03473 10.9404 2.91553C10.8212 2.79634 10.7543 2.63467 10.7543 2.4661V0.635593C10.7543 0.467023 10.8212 0.305358 10.9404 0.186161C11.0596 0.0669641 11.2213 0 11.3899 0C11.5584 0 11.7201 0.0669641 11.8393 0.186161C11.9585 0.305358 12.0255 0.467023 12.0255 0.635593V2.4661C12.0255 2.63467 11.9585 2.79634 11.8393 2.91553C11.7201 3.03473 11.5584 3.10169 11.3899 3.10169Z" fill={color} />
    <path d="M3.6102 15C3.44163 15 3.27997 14.933 3.16077 14.8139C3.04157 14.6947 2.97461 14.533 2.97461 14.3644V12.5339C2.97461 12.3653 3.04157 12.2037 3.16077 12.0845C3.27997 11.9653 3.44163 11.8983 3.6102 11.8983C3.77877 11.8983 3.94044 11.9653 4.05963 12.0845C4.17883 12.2037 4.2458 12.3653 4.2458 12.5339V14.3644C4.2458 14.533 4.17883 14.6947 4.05963 14.8139C3.94044 14.933 3.77877 15 3.6102 15Z" fill={color} />
    <path d="M6.20334 15C6.03477 15 5.87311 14.933 5.75391 14.8139C5.63471 14.6947 5.56775 14.533 5.56775 14.3644V12.5339C5.56775 12.3653 5.63471 12.2037 5.75391 12.0845C5.87311 11.9653 6.03477 11.8983 6.20334 11.8983C6.37191 11.8983 6.53358 11.9653 6.65277 12.0845C6.77197 12.2037 6.83894 12.3653 6.83894 12.5339V14.3644C6.83894 14.533 6.77197 14.6947 6.65277 14.8139C6.53358 14.933 6.37191 15 6.20334 15Z" fill={color} />
    <path d="M8.7966 15C8.62803 15 8.46637 14.933 8.34717 14.8139C8.22798 14.6947 8.16101 14.533 8.16101 14.3644V12.5339C8.16101 12.3653 8.22798 12.2037 8.34717 12.0845C8.46637 11.9653 8.62803 11.8983 8.7966 11.8983C8.96517 11.8983 9.12684 11.9653 9.24604 12.0845C9.36523 12.2037 9.4322 12.3653 9.4322 12.5339V14.3644C9.4322 14.533 9.36523 14.6947 9.24604 14.8139C9.12684 14.933 8.96517 15 8.7966 15Z" fill={color} />
    <path d="M11.3899 15C11.2213 15 11.0596 14.933 10.9404 14.8139C10.8212 14.6947 10.7543 14.533 10.7543 14.3644V12.5339C10.7543 12.3653 10.8212 12.2037 10.9404 12.0845C11.0596 11.9653 11.2213 11.8983 11.3899 11.8983C11.5584 11.8983 11.7201 11.9653 11.8393 12.0845C11.9585 12.2037 12.0255 12.3653 12.0255 12.5339V14.3644C12.0255 14.533 11.9585 14.6947 11.8393 14.8139C11.7201 14.933 11.5584 15 11.3899 15Z" fill={color} />
    <path d="M14.3644 4.2458H12.5339C12.3653 4.2458 12.2037 4.17883 12.0845 4.05963C11.9653 3.94044 11.8983 3.77877 11.8983 3.6102C11.8983 3.44163 11.9653 3.27997 12.0845 3.16077C12.2037 3.04157 12.3653 2.97461 12.5339 2.97461H14.3644C14.533 2.97461 14.6947 3.04157 14.8139 3.16077C14.933 3.27997 15 3.44163 15 3.6102C15 3.77877 14.933 3.94044 14.8139 4.05963C14.6947 4.17883 14.533 4.2458 14.3644 4.2458Z" fill={color} />
    <path d="M14.3644 6.83894H12.5339C12.3653 6.83894 12.2037 6.77197 12.0845 6.65277C11.9653 6.53358 11.8983 6.37191 11.8983 6.20334C11.8983 6.03477 11.9653 5.87311 12.0845 5.75391C12.2037 5.63471 12.3653 5.56775 12.5339 5.56775H14.3644C14.533 5.56775 14.6947 5.63471 14.8139 5.75391C14.933 5.87311 15 6.03477 15 6.20334C15 6.37191 14.933 6.53358 14.8139 6.65277C14.6947 6.77197 14.533 6.83894 14.3644 6.83894Z" fill={color} />
    <path d="M14.3644 9.4322H12.5339C12.3653 9.4322 12.2037 9.36523 12.0845 9.24604C11.9653 9.12684 11.8983 8.96517 11.8983 8.7966C11.8983 8.62803 11.9653 8.46637 12.0845 8.34717C12.2037 8.22798 12.3653 8.16101 12.5339 8.16101H14.3644C14.533 8.16101 14.6947 8.22798 14.8139 8.34717C14.933 8.46637 15 8.62803 15 8.7966C15 8.96517 14.933 9.12684 14.8139 9.24604C14.6947 9.36523 14.533 9.4322 14.3644 9.4322Z" fill={color} />
    <path d="M14.3644 12.0255H12.5339C12.3653 12.0255 12.2037 11.9585 12.0845 11.8393C11.9653 11.7201 11.8983 11.5584 11.8983 11.3899C11.8983 11.2213 11.9653 11.0596 12.0845 10.9404C12.2037 10.8212 12.3653 10.7543 12.5339 10.7543H14.3644C14.533 10.7543 14.6947 10.8212 14.8139 10.9404C14.933 11.0596 15 11.2213 15 11.3899C15 11.5584 14.933 11.7201 14.8139 11.8393C14.6947 11.9585 14.533 12.0255 14.3644 12.0255Z" fill={color} />
    <path d="M2.4661 4.2458H0.635593C0.467023 4.2458 0.305358 4.17883 0.186161 4.05963C0.0669641 3.94044 0 3.77877 0 3.6102C0 3.44163 0.0669641 3.27997 0.186161 3.16077C0.305358 3.04157 0.467023 2.97461 0.635593 2.97461H2.4661C2.63467 2.97461 2.79634 3.04157 2.91553 3.16077C3.03473 3.27997 3.10169 3.44163 3.10169 3.6102C3.10169 3.77877 3.03473 3.94044 2.91553 4.05963C2.79634 4.17883 2.63467 4.2458 2.4661 4.2458Z" fill={color} />
    <path d="M2.4661 6.83894H0.635593C0.467023 6.83894 0.305358 6.77197 0.186161 6.65277C0.0669641 6.53358 0 6.37191 0 6.20334C0 6.03477 0.0669641 5.87311 0.186161 5.75391C0.305358 5.63471 0.467023 5.56775 0.635593 5.56775H2.4661C2.63467 5.56775 2.79634 5.63471 2.91553 5.75391C3.03473 5.87311 3.10169 6.03477 3.10169 6.20334C3.10169 6.37191 3.03473 6.53358 2.91553 6.65277C2.79634 6.77197 2.63467 6.83894 2.4661 6.83894Z" fill={color} />
    <path d="M2.4661 9.4322H0.635593C0.467023 9.4322 0.305358 9.36523 0.186161 9.24604C0.0669641 9.12684 0 8.96517 0 8.7966C0 8.62803 0.0669641 8.46637 0.186161 8.34717C0.305358 8.22798 0.467023 8.16101 0.635593 8.16101H2.4661C2.63467 8.16101 2.79634 8.22798 2.91553 8.34717C3.03473 8.46637 3.10169 8.62803 3.10169 8.7966C3.10169 8.96517 3.03473 9.12684 2.91553 9.24604C2.79634 9.36523 2.63467 9.4322 2.4661 9.4322Z" fill={color} />
    <path d="M2.4661 12.0255H0.635593C0.467023 12.0255 0.305358 11.9585 0.186161 11.8393C0.0669641 11.7201 0 11.5584 0 11.3899C0 11.2213 0.0669641 11.0596 0.186161 10.9404C0.305358 10.8212 0.467023 10.7543 0.635593 10.7543H2.4661C2.63467 10.7543 2.79634 10.8212 2.91553 10.9404C3.03473 11.0596 3.10169 11.2213 3.10169 11.3899C3.10169 11.5584 3.03473 11.7201 2.91553 11.8393C2.79634 11.9585 2.63467 12.0255 2.4661 12.0255Z" fill={color} />
  </svg>
);
