export const isFwVersionGreatestOrEqual = (referenceVersion: string, devFwVersion: string): boolean => {
  if (!referenceVersion && !devFwVersion) return false;
  const [rVersion0, rVersion1, rVersion2] = referenceVersion.split('_');
  const [dVersion0, dVersion1, dVersion2] = devFwVersion.split('_');

  if (dVersion0 > rVersion0) return true;
  if (dVersion0 < rVersion0) return false;

  if (dVersion1 > rVersion1) return true;
  if (dVersion1 < rVersion1) return false;

  if (dVersion2 > rVersion2) return true;
  if (dVersion2 < rVersion2) return false;

  return true; // Todas partes são iguais
};
