interface UrgentHealthIconProps {
  color?: string;
  height?: string;
  width?: string;
}

export const UrgentHealthIcon = ({ color, height, width }: UrgentHealthIconProps): JSX.Element => (
  <svg width={width || '26'} height={height || '26'} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M25.6185 7.29878C25.4956 7.829 25.4207 8.37718 25.2411 8.88627C24.6336 10.608 23.5651 11.9231 21.8788 12.6081C20.711 13.0825 19.5295 12.971 18.3785 12.503C18.1664 12.4167 18.051 12.4408 17.8916 12.6051C14.0174 16.602 10.1347 20.5903 6.25992 24.5867C5.60084 25.2664 4.82709 25.6638 3.89251 25.6256C2.60171 25.5728 1.64587 24.9282 1.12635 23.711C0.610996 22.5035 0.816533 21.3669 1.62672 20.3482C1.72199 20.2283 1.83346 20.1219 1.94015 20.0121C5.79073 16.0493 9.64028 12.0856 13.4969 8.12908C13.6725 7.94885 13.6946 7.81289 13.6067 7.57759C12.6728 5.07594 13.5385 2.6349 15.8769 1.15061C16.6922 0.633067 17.574 0.307376 18.5311 0.207909C18.5989 0.200884 18.6644 0.169614 18.7309 0.149658C19.0532 0.149658 19.3753 0.149658 19.6976 0.149658C20.1792 0.246203 20.6608 0.342811 21.1424 0.439356C21.1484 0.473485 21.1545 0.507677 21.1606 0.541806C21.0768 0.614976 20.9876 0.682552 20.9101 0.762125C19.7922 1.90991 18.6772 3.06093 17.5568 4.20623C17.4328 4.33298 17.3646 4.44712 17.4127 4.63841C17.6583 5.61766 17.8868 6.60164 18.1396 7.5789C18.1678 7.68788 18.2979 7.81955 18.4046 7.84864C19.3443 8.10508 20.2906 8.33584 21.2318 8.5865C21.4216 8.63704 21.5432 8.59638 21.6809 8.45327C22.7989 7.29088 23.9265 6.13831 25.0465 4.97796C25.1218 4.89995 25.1588 4.78289 25.2137 4.68398C25.2561 4.71034 25.2984 4.73676 25.3409 4.76312C25.4334 5.23534 25.5259 5.70755 25.6185 6.17977C25.6185 6.55277 25.6185 6.92577 25.6185 7.29878ZM3.95975 21.3965C3.38809 21.3955 2.94814 21.8387 2.93992 22.4239C2.93153 23.0209 3.37843 23.4925 3.95419 23.4943C4.51746 23.496 4.96593 23.0409 4.97281 22.4608C4.98 21.8557 4.54392 21.3976 3.95975 21.3965Z" fill={color || '#E00030'} />
  </svg>
);
