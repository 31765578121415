type UnknownHealthDarkIconProps = {
  color?: string;
}

export const UnknownHealthDarkIcon = ({ color = '#fff', ...props }: UnknownHealthDarkIconProps): JSX.Element => (
  <svg {...props} width="20" height="20" viewBox="-2 -2 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="m 9.9234938,16.303637 q 0,-2.042512 0.4960392,-3.25343 0.496037,-1.210917 1.809081,-2.378067 1.327634,-1.1817393 1.765314,-1.9112087 0.437682,-0.7440579 0.437682,-1.5610627 0,-2.465604 -2.275943,-2.465604 -1.079613,0 -1.736134,0.6711109 Q 9.7775998,6.0618981 9.7484198,7.2290479 H 5.5175024 Q 5.5466843,4.4424768 7.3119955,2.8668246 9.091899,1.2911723 12.155667,1.2911723 q 3.092948,0 4.799904,1.5027053 1.706957,1.488117 1.706957,4.2163295 0,1.2400968 -0.554396,2.3488894 -0.554397,1.0942025 -1.940386,2.4364245 l -1.18174,1.123382 q -1.108792,1.065024 -1.269276,2.494782 l -0.05836,0.889952 z m -0.423092,4.478938 q 0,-0.977489 0.6565212,-1.604832 0.671112,-0.641931 1.706956,-0.641931 1.035846,0 1.692369,0.641931 0.671111,0.627343 0.671111,1.604832 0,0.962898 -0.656521,1.590242 -0.641934,0.627342 -1.706959,0.627342 -1.065023,0 -1.721544,-0.627342 -0.6419332,-0.627344 -0.6419332,-1.590242 z"
    />
  </svg>
);
