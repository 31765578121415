import { useEffect, useState, useRef } from 'react';

import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  ResponsiveContainer, CartesianGrid, XAxis, YAxis, ScatterChart, Scatter, Label, LineChart, Line, Tooltip, ReferenceArea, Dot, ReferenceLine,
} from 'recharts';
import { Flex, Box } from 'reflexbox';
import { CSVLink } from 'react-csv';
import {
  Button, Datepicker, Overlay, Loader,
} from '~/components';
import { formatArrHistory, getEndTime, processReceivedHistoryDAC } from '~/helpers';
import * as axisCalc from '~/helpers/axisCalc';
import { getCachedDevInfo, getCachedDevInfoSync } from '~/helpers/cachedStorage';
import { getUserProfile } from '~/helpers/userProfile';
import { useStateVar } from '~/helpers/useStateVar';
import { CloseIcon, CalendarIcon, ExportWorksheetIcon } from '~/icons';
import { apiCall, ApiResps } from '../../../../providers';
import { colors } from '~/styles/colors';
import { ChartLine, colorPalete } from '~/components/ChartRecharts';
import { LegendaGroup } from '~/components/ChartRecharts/Legenda';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import i18n from '~/i18n';

import {
  GraphWrapper,
  ModalMobile,
  ColoredDottedLine,
  ColoredLine,
  CheckboxLine,
  Text,
  ModalSection,
  ModalTitleContainer,
  ModalTitle,
  MobileWrapper,
  DesktopWrapper,
  CardWrapper,
  CustomLabel,
} from './styles';
import { NoGraph } from '~/components/NoGraph';
import { formatNumberWithFractionDigits } from '~/helpers/thousandFormatNumber';

const colorList = [
  '#e6194B',
  '#3cb44b',
  '#c7ad00',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#96ca11',
  '#f25991',
  '#469990',
  '#dcbeff',
  '#9A6324',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
];

const varsProperties = {
  Tamb: {
    colorDefault: colors.Green,
    colorFanCoil: colors.Pink100,
    strokeDasharray: '',
    type: 'monotone',
  },
  Tsuc: {
    colorDefault: colors.Blue300,
    colorFanCoil: colors.Red,
    colorHeat: colors.Blue300,
    strokeDasharray: '',
    type: 'monotone',
  },
  Tliq: {
    colorDefault: colors.Red,
    colorFanCoil: colors.Blue300,
    colorHeat: colors.Red,
    strokeDasharray: '',
    type: 'monotone',
  },
  Psuc: {
    colorDefault: colors.Blue300,
    colorFanCoil: colors.Blue300,
    strokeDasharray: '5 5',
    type: 'monotone',
  },
  Pliq: {
    colorDefault: colors.Red,
    colorFanCoil: colors.Red,
    strokeDasharray: '5 5',
    type: 'monotone',
  },
  Tsc: {
    colorDefault: colors.Blue500,
    colorFanCoil: colors.Blue500,
    strokeDasharray: '3 3',
    type: 'monotone',
  },
  Tsh: {
    colorDefault: colors.Pink400,
    colorFanCoil: colors.Pink400,
    strokeDasharray: '3 3',
    type: 'monotone',
  },
  Lcmp: {
    colorDefault: colors.Grey400,
    colorFanCoil: colors.Grey400,
    strokeDasharray: '',
    type: 'step',
  },
  Levp: {
    colorDefault: colors.Grey400,
    colorFanCoil: colors.Grey400,
    strokeDasharray: '',
    type: 'step',
  },
  Lcut: {
    colorDefault: colors.Orange600,
    colorFanCoil: colors.Orange600,
    strokeDasharray: '',
    type: 'step',
  },
  L1raw: {
    colorDefault: colors.Grey400,
    colorFanCoil: colors.Grey400,
    strokeDasharray: '',
    type: 'step',
  },
  L1fancoil: {
    colorDefault: colors.Grey400,
    colorFanCoil: colors.Grey400,
    strokeDasharray: '',
    type: 'step',
  },
  deltaT: {
    colorDefault: '#181842',
    colorFanCoil: '#181842',
    strokeDasharray: '3 3',
    type: 'monotone',
  },
  Icomp: {
    colorDefault: colors.Green_v2,
    colorFanCoil: colors.Green_v2,
    strokeDasharray: '',
    type: 'monotone',
  },
  Vinsuf: {
    colorDefault: colors.Yellow_v3,
    colorFanCoil: colors.Yellow_v3,
    strokeDasharray: '',
    type: 'monotone',
  },
};

let CSVheader = [] as any;

let varsFix = [] as any;

function calculateGraphData(faultsChart) {
  const booleanLines = faultsChart.lines.filter((line) => line.isBool || line.length === 0).map((line) => line.data);

  const { tempLimits, tempTicks, L1start } = axisCalc.calculateAxisInfo({}, booleanLines.length);
  faultsChart.L1start = L1start;
  faultsChart.leftLimits = tempLimits;
  faultsChart.leftTicks = tempTicks;
  const ticksValues = axisCalc.updateBoolY(booleanLines, L1start);

  const boolTicksNames = {};
  for (let i = 0; i < booleanLines.length; i++) {
    const [tick_0, tick_1] = ticksValues[i];
    boolTicksNames[String(tick_0)] = faultsChart.lines[i].lineId;
    boolTicksNames[String(tick_1)] = '';
  }
  faultsChart.boolTicksNames = boolTicksNames;
}

function processFaultsData(histData: { [lineId: string]: { x: number; L: number; }[] }, faultsChart) {
  const lines = [] as { lineId: string, color: string, data: { x:number, L:number }[] }[]; // { lineId: 'CHAV_d', color: 'blue', data: [{ x: 10, y: 20 }] }
  let colorIndex = 0;
  for (const [faultId, line] of Object.entries(histData)) {
    lines.push({
      lineId: faultId,
      isBool: true,
      color: colorList[colorIndex],
      data: line,
    });
    colorIndex = (colorIndex + 1) % colorList.length;
  }

  faultsChart.lines = lines;
  calculateGraphData(faultsChart);
}

export const DACHistoric = (): JSX.Element => {
  moment.locale(i18n.language === 'pt' ? 'pt-BR' : 'en');
  const routeParams = useParams<{ devId }>();
  const history = useHistory();
  const [, render] = useStateVar({});
  const devInfo = getCachedDevInfoSync(routeParams.devId);
  return (
    <>
      <Helmet>
        <title>{t('tituloPagDielEnergiaHistorico')}</title>
      </Helmet>
      <DACHistoricContents onDevInfoUpdate={render} />
    </>
  );
};
export function DACHistoricContents({ onDevInfoUpdate = undefined as undefined|(() => void) }) {
  const [profile] = useState(getUserProfile);
  const csvLinkEl = useRef();
  const { devId: dacId } = useParams<{ devId: string }>();
  const [state, render, setState] = useStateVar(() => {
    const state = {
      xDomain: null as null|([number, number]),
      xTicks: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24] as number[],
      refAreaLeft: null as null|number,
      refAreaRight: null as null|number,
      multiDays: false,
      numDays: 1 as number,
      dateStart: null as null|moment.Moment,
      dateEnd: null as null|moment.Moment,
      tomorrow: moment(moment().add(1, 'days').format('YYYY-MM-DD')),
      chartData: {
        x: [] as number[],
        vars: null as null|{
          id: string;
          name: string;
          unit?: string;
          y: (null|number)[];
          strokeDasharray: string;
          type: string;
          color: string;
          axisId: string;
          maxY?: null|number;
          minY?: null|number;
          y_orig?: (null|number|string)[];
          steps?: {
            y_orig: (null|number|string);
            y_chart: (null|number);
            label: string;
          }[];
          checked?: boolean;
        }[],
      },
      numDeparts: [] as number[],
      csvData: [] as {}[],
      focused: false,
      focusedInput: null as 'endDate'|'startDate'|null,
      dacId,
      devInfo: getCachedDevInfoSync(dacId),
      selectedL1Sim: undefined as undefined|boolean,
      isModalOpen: false,
      isLoading: false,
      gotHwCfg: false,
      withFaults: false,
      withPsuc: false,
      date: getEndTime(),
      usePsi: (profile.prefsObj.pressureUnit === 'psi'),
      axisDataLimits: {} as { minTval?: number, maxTval?: number, minPval?: number, maxPval?: number },
      axisInfo: {
        L1start: null as null|number,
        tempLimits: [-5, 30],
        tempTicks: [-5, 0, 5, 10, 15, 20, 25, 30],
        presLimits: [-5, 35],
        presTicks: [-5, 0, 5, 10, 15, 20, 25, 30, 35],
      },
      faultsChart: {
        L1start: null,
        leftLimits: [-5, 30],
        leftTicks: [-5, 0, 5, 10, 15, 20, 25, 30],
        lines: [], // { lineId: 'CHAV_d', color: 'blue', data: [{ x: 10, y: 20 }] }
        boolTicksNames: {}, // { '-1.1': 'LIGADO' }
      },
      graphEnable: {
        Tamb: true,
        Tsuc: true,
        Tliq: true,
        deltaT: true,
        Psuc: true,
        Pliq: true,
        Tsc: true,
        Tsh: true,
        Lcmp: true,
        Levp: true,
        Lcut: true,
        Icomp: true,
        L1raw: false,
        L1fancoil: false,
        t_lim: true,
        deltaT_lim: true,
        Vinsuf: true,
      },
      hwCfg: {
        hasPliq: true, hasPsuc: true, hasTsc: true, hasTsh: true, hasAutomation: false, isFanCoil: false,
      },
      heatExchangerInfo: {} as {
        ID: number
        NAME: string
        T_MIN: number
        T_MAX: number
        DELTA_T_MIN: number
        DELTA_T_MAX: number
      } | null,
    };
    return state;
  });

  const {
    hwCfg, axisInfo, graphEnable, chartData,
  } = state;
  // const readyToRequestHistory = state.connectedWS && state.gotHwCfg;

  function setFormattedDate(date) {
    state.date = date.set({ hour: 0 });
    render();
  }

  function CheckPermDetected(fault: {
    detected_fault_id: number;
    timestamp: string;
    dev_id: string;
    rise: boolean;
    approved: boolean | null;
    source: 'Endpoint' | 'FR';
    restab: boolean;
    fault_id: string;
    name: string;
    gravity: string;
    approval_type: string;
    permanence_detected: boolean;
  }) {
    if (fault.permanence_detected === true && fault.restab === false) {
      return '(Lógica Permanência)';
    }
    if (fault.restab === true) {
      return '(Restabelecimento)';
    }
    return '';
  }

  function processFault(fault, faultsHist, definitions, gravityTranslation, periodStart) {
    if (fault.source === 'Endpoint') return;

    let approvalState = '';
    if (fault.approved === true) {
      approvalState = ' - Approved';
    } else if (fault.approved === false) {
      approvalState = ' - Rejected';
    }

    const isPermDetected = CheckPermDetected(fault);
    const desc = `${isPermDetected} ${fault.fault_id}${approvalState}`;
    const faultText = `${definitions[fault.fault_id].name} - ${gravityTranslation[definitions[fault.fault_id].gravity]}`;

    if (!faultsHist[faultText]) {
      faultsHist[faultText] = [];
    }

    faultsHist[faultText].push({
      x: moment.duration(moment.utc(fault.timestamp).diff(periodStart)).asHours(),
      L: 0,
      desc,
      time: moment.utc(fault.timestamp),
    });
  }

  async function getFaultHistory() {
    try {
      const faultsHist = {} as {
          [faultId : string]: {
            x: number
            L:number
            desc: string
          }[]
        };
      if (state.withFaults) {
        const periodStart = moment(state.dateStart).startOf('day').utc();
        const faultParams = {
          dev_id: state.dacId,
          start_time: periodStart.utc().format('YYYY-MM-DDTHH:mm:ss'),
          end_time: moment(state.dateEnd).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss'),
        };
        const [faultDefs, faults] = await Promise.all([apiCall('/dac/get-fault-descs'), apiCall('/dac/get-fr-history', faultParams)]);
        if (!faultDefs || !faults || !faults.history) {
          console.log('Não foi possível obter definições de falhas ou histórico de falhas');
          toast.error(t('erroObterDadosDeFalha'));
          state.isLoading = false;
          render();
          return;
        }
        const gravityTranslation = {
          Green: 'Verde',
          Yellow: 'Amarela',
          Orange: 'Laranja',
          Red: 'Vermelha',
        };

        const definitions = {};

        for (const faultDef of faultDefs.defs) {
          definitions[faultDef.fault_id] = faultDef;
        }

        for (const fault of faults.history) {
          processFault(fault, faultsHist, definitions, gravityTranslation, periodStart);
        }
        processFaultsData(faultsHist, state.faultsChart);
      }
    } catch (err) { console.log(err); toast.error(t('erro')); }
  }

  useEffect(() => {
    if (!state.gotHwCfg) return;
    if (!state.dateStart || !state.dateEnd) return;

    Promise.resolve().then(async () => {
      if (!state.isLoading) {
        state.isLoading = true;
        render();
      }
      try {
        const d1 = new Date(`${moment(state.dateStart).format('YYYY-MM-DD')}T00:00:00Z`).getTime();
        const d2 = new Date(`${moment(state.dateEnd).format('YYYY-MM-DD')}T00:00:00Z`).getTime();
        const numDays = Math.round((d2 - d1) / 1000 / 60 / 60 / 24) + 1;
        state.numDays = numDays;
        if ((numDays >= 1) && (numDays <= 15)) { } // OK
        else {
          toast.error(t('periodoDe1a15Dias'));
          state.isLoading = false;
          render();
          return;
        }

        // FEATURE DISPONIVEL APENAS PARA OS CLIENTES: BANCO DO BRASIL e DIEL ENERGIA
        state.graphEnable.Icomp = (state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && (state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2);

        const params = {
          dacId: state.dacId,
          dayYMD: moment(state.dateStart).format('YYYY-MM-DD'),
          selectedParams: ['Tamb', 'Tsuc', 'Tliq', 'Psuc', 'Pliq', 'Tsc', 'Tsh', 'Lcmp', 'Levp', 'Lcut', 'Icomp', 'Vinsuf'],
          numDays,
          usePsi: state.usePsi,
          isFanCoil: state.hwCfg.isFanCoil,
          hasAutomation: state.hwCfg.hasAutomation,
        };
        const {
          vars, commonX, limts, numDeparts,
        } = await apiCall('/dac/get-charts-data-common', params);

        if (!profile.manageAllClients) {
          vars.Psuc.y = vars.Psuc.y.map((psuc) => {
            if (psuc && psuc === (-2.8)) {
              return null;
            }
            if (psuc && psuc !== (-2.8)) {
              state.withPsuc = true;
            }
            return psuc;
          });
        }

        varsFix = vars;
        state.chartData.x = commonX;
        state.numDeparts = numDeparts;

        if (state.hwCfg.isFanCoil || state.devInfo.dac!.DAC_APPL === 'trocador-de-calor') {
          vars.deltaT = JSON.parse(JSON.stringify(vars.Tliq));
          vars.Tsuc.name = t('temperaturaSaidaAgua');
          vars.Tliq.name = state.devInfo.dac!.DAC_APPL === 'trocador-de-calor' ? t('temperaturaRetornoAgua') : t('temperaturaEntradaAgua');
          vars.Tamb.name = t('temperaturaExterna');
          vars.deltaT.name = 'ΔT';
          for (let i = 0; i < vars.Tliq.y.length; i++) {
            if (vars.Tliq.y[i] && vars.Tsuc.y[i]) vars.deltaT.y[i] = Number((vars.Tliq.y[i] - vars.Tsuc.y[i]).toFixed(2));
          }
        }

        if (state.devInfo.dac.DAC_TYPE === 'self') {
          vars.Tamb.name = t('temperaturaRetorno');
        }

        insertVars(vars);

        const debug_L1_fancoil = state.hwCfg.isFanCoil && profile.manageAllClients;

        // const boolLines = state.hwCfg.hasAutomation ? [vars.Levp, vars.Lcut] : [vars.Lcmp];
        const boolLines = state.hwCfg.hasAutomation
          ? (debug_L1_fancoil ? [vars.L1raw, vars.L1fancoil, vars.Lcut] : [vars.Levp, vars.Lcut])
          : (debug_L1_fancoil ? [vars.L1raw, vars.L1fancoil] : [vars.Lcmp]);
        state.axisDataLimits = axisCalc.updateDataLimits(limts);
        state.axisInfo = axisCalc.calculateAxisInfo(state.axisDataLimits, boolLines.length);

        // Ajuste quando valor mínimo de pressão é negativo
        if (state.axisDataLimits.minPval && state.axisDataLimits.minPval < 0) {
          state.axisInfo.presLimits = [state.axisInfo.presLimits[0] - 1, state.axisInfo.presLimits[1]];
        }

        state.xDomain = [0, 24 * numDays];
        state.xTicks = Array.from({ length: 13 }, (_, i) => i * 2 * numDays);

        axisCalc.updateBoolY(boolLines, state.axisInfo.L1start!);
      } catch (err) { console.log(err); toast.error(t('erro')); }

      await getFaultHistory();
      state.isLoading = false;
      render();
    });
  }, [state.gotHwCfg, JSON.stringify(state.date), state.dateStart, state.dateEnd, state.withFaults]);

  useEffect(() => {
    (async function () {
      const devInfo = await getCachedDevInfo(state.dacId, {});
      if (onDevInfoUpdate) onDevInfoUpdate();
      state.devInfo = devInfo;
      const dacInfo = devInfo.dac;
      state.hwCfg.hasPsuc = (dacInfo.P0Psuc || dacInfo.P1Psuc);
      state.hwCfg.hasPliq = (dacInfo.P0Pliq || dacInfo.P1Pliq);
      state.hwCfg.hasTsh = state.hwCfg.hasPsuc;
      state.hwCfg.hasTsc = state.hwCfg.hasPliq;
      state.hwCfg.hasAutomation = dacInfo.hasAutomation;
      state.hwCfg.isFanCoil = (dacInfo.DAC_APPL === 'fancoil');
      if (state.hwCfg.isFanCoil) {
        state.selectedL1Sim = state.devInfo.dac.SELECTED_L1_SIM === '1';
        if (profile.manageAllClients) {
          state.graphEnable.Lcmp = false;
          state.graphEnable.Levp = false;
          state.graphEnable.L1raw = true;
          state.graphEnable.L1fancoil = true;
        }
      }
      if (!state.hwCfg.hasPsuc) state.graphEnable.Psuc = false;
      if (!state.hwCfg.hasPliq) state.graphEnable.Pliq = false;
      if (!state.hwCfg.hasAutomation) state.graphEnable.Levp = false;
      if (!state.hwCfg.hasAutomation) state.graphEnable.Lcut = false;
      if (state.hwCfg.hasAutomation) state.graphEnable.Lcmp = false;
      state.gotHwCfg = true;

      const heatExchangeId = dacInfo!.HEAT_EXCHANGER_ID;
      if (heatExchangeId && dacInfo!.DAC_APPL === 'trocador-de-calor') {
        state.heatExchangerInfo = await apiCall('/heat-exchanger/get-info-v2', { CLIENT_ID: devInfo.CLIENT_ID, HEAT_EXCHANGER_ID: heatExchangeId });
      }
      render();
    }()).catch(console.log);
  }, []);

  function zoom() {
    let { refAreaLeft, refAreaRight } = state;

    if (refAreaLeft === refAreaRight || refAreaRight == null || refAreaLeft == null) {
      setState({
        refAreaLeft: null,
        refAreaRight: null,
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    setState({
      refAreaLeft: null,
      refAreaRight: null,
      xDomain: [refAreaLeft, refAreaRight],
      xTicks: [],
    });
  }

  function zoomOut() {
    setState({
      refAreaLeft: null,
      refAreaRight: null,
      xDomain: null,
      xTicks: Array.from({ length: 13 }, (_, i) => i * 2 * state.numDays),
    });
  }

  function insertVars(vars: any) {
    const chartVars: (typeof chartData.vars) = [];
    let i = 0;

    let tsucIndex: number | null = null;
    let tliqIndex: number | null = null;

    for (const key in vars) {
      if (vars.hasOwnProperty(key) && vars[key].y.length > 0 && vars[key].y.some((value) => value !== null) && graphEnable[key]) {
        // Se for trocador de calor
        if (state.devInfo.dac!.DAC_APPL === 'trocador-de-calor') {
          if (key === 'Tliq') {
            tliqIndex = i;
          }
          if (key === 'Tsuc') {
            tsucIndex = i;
          }
        }

        chartVars.push({
          id: key,
          name: vars[key].name,
          y: vars[key].y,
          color: state.devInfo.dac!.DAC_APPL === 'trocador-de-calor' && varsProperties[key].colorHeat ? varsProperties[key].colorHeat : hwCfg.isFanCoil ? varsProperties[key].colorFanCoil : varsProperties[key].colorDefault,
          strokeDasharray: varsProperties[key].strokeDasharray,
          axisId: (key === 'Psuc' || key === 'Pliq' ? 'press' : 'temp'),
          checked: graphEnable[key].checked,
          type: varsProperties[key].type,
          maxY: 35,
          minY: 0,
        });
      }

      i++;
    }

    // Altera os índices de Tsuc e Tliq
    if (tsucIndex !== null && tliqIndex !== null) {
      const temp = chartVars[tsucIndex];
      chartVars[tsucIndex] = chartVars[tliqIndex];
      chartVars[tliqIndex] = temp;
    }

    state.chartData.vars = chartVars;
  }

  function toolTipFormater(
    value: number,
    accessor: string|Function,
    payload: {
      value: number,
      payload: number, // x index
      name: string,
    },
    index: number,
  ) {
    if (state.chartData.vars && state.axisInfo.L1start !== null) {
      const varInfo = (index < state.chartData.vars.length) && state.chartData.vars.filter((data) => data.name === payload.name)[0];
      const nameVar = (varInfo && varInfo.name) || String(index);
      const id = (varInfo && varInfo.id) || String(index);
      const isBool = id === 'Levp' || id === 'Lcut' || id === 'Lcmp' || id === 'L1raw' || id === 'L1fancoil';
      let unidade = (varInfo && varInfo.axisId === 'temp' && !isBool ? '°C' : ' '); // adicionar unidade de corrente para compressor
      unidade = varInfo && varInfo.id === 'Icomp' ? ' A' : unidade;
      unidade = varInfo && varInfo.id === 'Vinsuf' ? ' m/s' : unidade;
      const isPressure = (id === 'Psuc');
      let label = formatNumberWithFractionDigits(value.toString());
      if (isPressure) {
        label = formatNumberWithFractionDigits(value.toFixed(1).toString());
      }
      if (isBool) {
        if (payload.value < (state.axisInfo.L1start - 5)) {
          // label = ((payload.value % 5) > -2.5) ? 'LIBERADO' : 'BLOQUEADO';
          if (state.hwCfg.isFanCoil
            && profile.manageAllClients
            && (payload.value < (state.axisInfo.L1start - 7) && payload.value > (state.axisInfo.L1start - 10))) {
            label = ((payload.value % 5) > -2.5) ? 'LIGADO' : 'DESLIGADO';
          } else {
            label = ((payload.value % 5) > -2.5) ? 'LIBERADO' : 'BLOQUEADO';
          }
        } else if (payload.value < state.axisInfo.L1start) {
          label = ((payload.value % 5) > -2.5) ? 'LIGADO' : 'DESLIGADO';
        }
      }
      return `${label}${unidade}`;
    }
    return '';
  }

  function csvValueFormatter(value: any, id: string) {
    if (state.chartData.vars && state.axisInfo.L1start !== null && value !== null && value !== undefined) {
      let label = value.toString();
      if (id === 'Levp' || id === 'Lcut' || id === 'Lcmp') {
        if (value < (state.axisInfo.L1start - 5)) {
          label = ((value % 5) > -2.5) ? 'LIBERADO' : 'BLOQUEADO';
        } else if (value < state.axisInfo.L1start) {
          label = ((value % 5) > -2.5) ? 'LIGADO' : 'DESLIGADO';
        }
      }
      const numberValue = parseFloat(label);
      if (!Number.isNaN(numberValue)) {
        return formatNumberWithFractionDigits(numberValue);
      }
      return `${label}`;
    }
    return '-';
  }

  function onMultidaysClick() {
    state.multiDays = !state.multiDays;
    if (!state.multiDays) {
      state.dateEnd = state.dateStart;
    }
    render();
  }

  function tooltipXLabelFormater(hour: number) {
    const numDays = Math.floor(hour / 24);
    const date = new Date(`${moment(state.dateStart).add(numDays + 1, 'days').format('YYYY-MM-DD')}T00:00:00Z`);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dateFinal = `${dd}/${mm}`;

    const hh = Math.floor(Math.abs(hour)) - 24 * numDays;
    const min = Math.floor((Math.abs(hour) * 60) % 60);
    const ss = Math.floor((Math.abs(hour) * 60 * 60) % 60);

    return `${dateFinal} - ${String(hh).padStart(2, '0')}:${String(min).padStart(2, '0')}:${String(ss).padStart(2, '0')}`;
  }

  function tickXLabelFormaterDay(hour: number) {
    const numDays = Math.floor(hour / 24);
    const date = new Date(`${moment(state.dateStart).add(numDays + 1, 'days').format('YYYY-MM-DD')}T00:00:00Z`);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');

    const dateFinal = `${dd}/${mm}`;
    return `${dateFinal}`;
  }

  function tickXLabelFormaterHour(hour: number) {
    const numDays = Math.floor(hour / 24);
    const sign = hour - 24 * numDays < 0 ? '-' : '';
    const hh = Math.floor(Math.abs(hour)) - 24 * numDays;
    const mm = Math.floor((Math.abs(hour) * 60) % 60);
    return `${'\n'} ${sign}${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}`;
  }

  function CsvLabelFromaterData(hour: number) {
    const numDays = Math.floor(hour / 24);
    const date = new Date(`${moment(state.dateStart).add(numDays + 1, 'days').format('YYYY-MM-DD')}T00:00:00Z`);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dateFinal = `${dd}/${mm}`;

    const hh = Math.floor(Math.abs(hour)) - 24 * numDays;
    const min = Math.floor((Math.abs(hour) * 60) % 60);
    const ss = Math.floor((Math.abs(hour) * 60 * 60) % 60);

    return `${dateFinal} ${String(hh).padStart(2, '0')}:${String(min).padStart(2, '0')}:${String(ss).padStart(2, '0')}`;
  }

  const renderQuarterTickHour = (tickProps: any) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    return <text x={x} y={y - 4} textAnchor="middle" className="recharts-text">{`${tickXLabelFormaterHour(value)}`}</text>;
  };

  const getCsvData = async () => {
    state.isLoading = true; render();
    const formattedCSV = [] as any;
    CSVheader = [];

    try {
      CSVheader.push({
        label: t('dataHora'),
        key: 'data',
      });

      if (state.chartData.vars && state.chartData.vars.length > 0) {
        for (const object of state.chartData.vars) {
          if (graphEnable[object.id]) {
            CSVheader.push({
              label: object.name,
              key: object.id,
            });
          }
        }

        CSVheader.push({
          label: t('numeroPartidas'),
          key: 'numDeparts',
        });

        let totalNumDeparts = 0;
        let indexNumDeparts = 0;
        for (let i = 0; i < state.chartData.x.length; i++) {
          if (state.chartData.vars) {
            let valueNumDeparts = 0;
            let insertNumDeparts = false;

            if (i > 0 && i < state.chartData.x.length - 1) {
              if (state.chartData.x[i] % 24 > state.chartData.x[i + 1] % 24) {
                valueNumDeparts = state.numDeparts[indexNumDeparts];
                insertNumDeparts = true;
                totalNumDeparts += valueNumDeparts;
                indexNumDeparts++;
              }
            }
            else if (i === state.chartData.x.length - 1) {
              valueNumDeparts = state.numDeparts[indexNumDeparts];
              insertNumDeparts = state.numDays > 1;
              totalNumDeparts += valueNumDeparts;
              indexNumDeparts++;
            }

            formattedCSV.push({
              data: `${CsvLabelFromaterData(state.chartData.x[i])}`,
              Tamb: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Tamb')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Tamb')[0].y[i], 'Tamb') : '-'),
              Tsuc: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Tsuc')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Tsuc')[0].y[i], 'Tsuc') : '-'),
              Tliq: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Tliq')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Tliq')[0].y[i], 'Tliq') : '-'),
              Psuc: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Psuc')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Psuc')[0].y[i], 'Psuc') : '-'),
              Pliq: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Pliq')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Pliq')[0].y[i], 'Pliq') : '-'),
              Tsc: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Tsc')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Tsc')[0].y[i], 'Tsc') : '-'),
              Tsh: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Tsh')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Tsh')[0].y[i], 'Tsh') : '-'),
              Lcmp: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Lcmp')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Lcmp')[0].y[i], 'Lcmp') : '-'),
              Levp: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Levp')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Levp')[0].y[i], 'Levp') : '-'),
              Lcut: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Lcut')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Lcut')[0].y[i], 'Lcut') : '-'),
              L1raw: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'L1raw')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'L1raw')[0].y[i], 'L1raw') : '-'),
              L1fancoil: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'L1fancoil')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'L1fancoil')[0].y[i], 'L1fancoil') : '-'),
              // FEATURE DISPONIVEL APENAS PARA OS CLIENTES: BANCO DO BRASIL e DIEL ENERGIA
              ...(((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && (state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2)) && { Icomp: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Icomp')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Icomp')[0].y[i], 'Icomp') : '-') }),
              ...(((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && ((state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2) && (state.devInfo.dac.EVAPORATOR_MODEL_ID || state.devInfo.dac.INSUFFLATION_SPEED))) && { Vinsuf: (state.chartData.vars && state.chartData.vars.filter((line) => line.id === 'Vinsuf')[0] ? csvValueFormatter(state.chartData.vars.filter((line) => line.id === 'Vinsuf')[0].y[i], 'Vinsuf') : '-') }),
              numDeparts: !insertNumDeparts ? null : valueNumDeparts,
            });
          }
        }

        const penultimo = CSVheader[CSVheader.length - 2].key;

        formattedCSV.push({
          data: null,
          Tamb: null,
          Tsuc: null,
          Tliq: null,
          Psuc: null,
          Pliq: null,
          Tsc: null,
          Tsh: null,
          Lcmp: null,
          Levp: null,
          Lcut: null,
          L1raw: null,
          L1fancoil: null,
          // FEATURE DISPONIVEL APENAS PARA OS CLIENTES: BANCO DO BRASIL e DIEL ENERGIA
          ...(((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && (state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2)) && { Icomp: null }),
          ...(((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && ((state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2) && (state.devInfo.dac.EVAPORATOR_MODEL_ID || state.devInfo.dac.INSUFFLATION_SPEED))) && { Vinsuf: null }),
          numDeparts: totalNumDeparts,
        });

        formattedCSV[formattedCSV.length - 1][penultimo] = t('totalPartidasPeriodo');

        state.csvData = formattedCSV;
        render();
        setTimeout(() => {
          (csvLinkEl as any).current.link.click();
        }, 1000);

        state.isLoading = false; render();
      }
      else {
        toast.info(t('semDadosGraficoExportar')); state.isLoading = false;
      }
    } catch (err) { console.log(err); toast.error(t('erro')); state.isLoading = false; }
  };

  const temperatureLabel = (() => {
    if (state.devInfo.dac && state.devInfo.dac.DAC_APPL === 'trocador-de-calor') {
      return t('deRetornoAgua');
    }
    if (hwCfg.isFanCoil) {
      return t('deEntradaAgua');
    }
    return t('deSuccao');
  })();

  const typeLoading = () => (state.isLoading ? 'disabled' : 'primary');

  const deltaTReferenceLines = () => {
    if (
      state.devInfo.dac
      && state.devInfo.dac.DAC_APPL === 'trocador-de-calor'
      && !!state.devInfo.dac.HEAT_EXCHANGER_ID
      && state.graphEnable.deltaT_lim
      && state.heatExchangerInfo
    ) {
      return (
        <>
          <ReferenceLine yAxisId="temp" y={state.heatExchangerInfo.DELTA_T_MAX} stroke="#005404" />
          <ReferenceLine yAxisId="temp" y={state.heatExchangerInfo.DELTA_T_MIN} stroke="#005404" />
        </>
      );
    }
    return null;
  };

  const refArea = () => {
    if (state.refAreaLeft && state.refAreaRight) {
      return <ReferenceArea yAxisId="temp" x1={state.refAreaLeft} x2={state.refAreaRight} strokeOpacity={0.3} />;
    }
    return null;
  };

  const temperatureReferenceLines = () => {
    if (
      state.devInfo.dac
      && state.devInfo.dac.DAC_APPL === 'trocador-de-calor'
      && !!state.devInfo.dac.HEAT_EXCHANGER_ID
      && state.graphEnable.t_lim
      && state.heatExchangerInfo
    ) {
      return (
        <>
          <ReferenceLine yAxisId="temp" y={state.heatExchangerInfo.T_MIN} stroke="#0fe80a" />
          <ReferenceLine yAxisId="temp" y={state.heatExchangerInfo.T_MAX} stroke="#0fe80a" />
        </>
      );
    }
    return null;
  };

  const returnNoGraph = (() => {
    if (!state.dateStart || !state.dateEnd) {
      return <NoGraph title={t('historicoDoDac')} />;
    }
    return null;
  })();

  const isFanCoilOrHeatExchanger = (() => {
    if (state.devInfo.dac.DAC_APPL === 'fancoil' || hwCfg.isFanCoil || state.devInfo.dac!.DAC_APPL === 'trocador-de-calor') {
      return true;
    }
    return false;
  })();

  const verifyClientsFeature = (() => {
    if ((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && (state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2)) {
      return true;
    }
    return false;
  })();

  const verifyhasAutomationNotFancoil = (() => {
    if ((hwCfg.hasAutomation) && (!hwCfg.isFanCoil || !profile.manageAllClients)) {
      return true;
    }
    return false;
  })();

  const verifyEvaporatorAndInsufflation = (() => {
    if ((state.devInfo.dac.EVAPORATOR_MODEL_ID || state.devInfo.dac.INSUFFLATION_SPEED))
    {
      return true;
    }
    return false;
  })();

  const isUsePsei = (() => {
    if (state.usePsi) {
      return 'PSI';
    }
    return 'Bar';
  })();

  const labelTempSelf = (() => {
    if (state.devInfo.dac.DAC_TYPE === 'self') {
      return t('deRetorno');
    }
    return t('externa');
  });

  const graphEnableTscOrTliq = (() => {
    if (state.devInfo.dac && isFanCoilOrHeatExchanger) {
      return graphEnable.Tsc;
    }
    return graphEnable.Tliq;
  });

  function lineChartFancoilHeatExchanger() {
    if (isFanCoilOrHeatExchanger) {
      return (
        <CheckboxLine>
          <Checkbox
            checked={graphEnable.deltaT}
            onChange={() => { graphEnable.deltaT = !graphEnable.deltaT; render(); }}
            value={graphEnable.deltaT}
            color="primary"
          />
          <Text>
            ΔT
            {' '}
            [°C]
          </Text>
          <ColoredDottedLine color="#181842" />
        </CheckboxLine>
      );
    }
  }

  const graphEnableTsucOrTliq = (() => {
    if (state.devInfo.dac && isFanCoilOrHeatExchanger) {
      return graphEnable.Tliq;
    }
    return graphEnable.Tsuc;
  });

  function featureClientsEvaporatorAndInsufflation() {
    if (verifyClientsFeature && verifyEvaporatorAndInsufflation) {
      return (
        (
          <CheckboxLine>
            <Checkbox
              checked={graphEnable.Vinsuf}
              onChange={() => { graphEnable.Vinsuf = !graphEnable.Vinsuf; insertVars(varsFix); render(); }}
              value={graphEnable.Vinsuf}
              color="primary"
            />
            <Text>
              {t('velocidadeDeInsuflamento')}
              {' '}
              [m/s]
            </Text>
            <ColoredLine color={colors.Yellow_v3} />
          </CheckboxLine>
        )
      );
    }
  }

  function lineLimitsTemperature() {
    if (state.devInfo.dac!.DAC_APPL === 'trocador-de-calor' && state.devInfo.dac!.HEAT_EXCHANGER_ID) {
      return (
        <>
          <CheckboxLine>
            <Checkbox
              checked={graphEnable.t_lim}
              onChange={() => { graphEnable.t_lim = !graphEnable.t_lim; render(); }}
              value={graphEnable.t_lim}
              color="primary"
            />
            <Text>
              {t('limitesTemperaturaSaidaAgua')}
            </Text>
            <ColoredLine color="#0fe80a" />
          </CheckboxLine>
          <CheckboxLine>
            <Checkbox
              checked={graphEnable.deltaT_lim}
              onChange={() => { graphEnable.deltaT_lim = !graphEnable.deltaT_lim; render(); }}
              value={graphEnable.deltaT_lim}
              color="primary"
            />
            <Text>
              {t('limitesTemperaturaT')}
            </Text>
            <ColoredLine color="#005404" />
          </CheckboxLine>
        </>
      );
    }
  }

  const loading = state.isLoading;

  return (
    <>
      <ModalMobile isModalOpen={state.isModalOpen}>
        <Flex mb={32}>
          <Box width={1}>
            <ModalSection>
              <ModalTitleContainer>
                <ModalTitle>{t('Filtrar por')}</ModalTitle>
                <CloseIcon size="12px" onClick={() => { state.isModalOpen = false; render(); }} />
              </ModalTitleContainer>
            </ModalSection>
          </Box>
        </Flex>
        <Flex flexWrap="wrap" mb={38}>
          <ContentDate>
            <DateLabel>{t('Data')}</DateLabel>
            <br />
            {state.isModalOpen && (!state.multiDays) && (
              <SingleDatePicker
                disabled={loading}
                date={state.dateStart}
                onDateChange={(value) => { setState({ dateStart: value, dateEnd: value }); }}
                focused={state.focused}
                onFocusChange={({ focused }) => setState({ focused })}
                id="datepicker"
                numberOfMonths={1}
                isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
              />
            )}
            {state.isModalOpen && (state.multiDays) && (
              <DateRangePicker
                disabled={loading}
                startDate={state.dateStart} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={state.dateEnd} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => { setState({ dateStart: startDate, dateEnd: startDate !== state.dateStart ? null : endDate }); }} // PropTypes.func.isRequired,
                onFocusChange={(focused: 'endDate'|'startDate'|null) => setState({ focusedInput: focused })}
                focusedInput={state.focusedInput}
                noBorder
                isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
              />
            )}
            <StyledCalendarIcon color="#202370" />
          </ContentDate>
          <CheckboxLine>
            <Checkbox
              checked={state.multiDays}
              onClick={onMultidaysClick}
              style={{ marginLeft: '20px' }}
            />
            <Text>
              {t('multiplosDias')}
            </Text>
          </CheckboxLine>
          <Box width={1}>
            <Button type="button" variant="primary" onClick={() => { state.isModalOpen = false; render(); }}>
              FILTRAR
            </Button>
          </Box>
        </Flex>
      </ModalMobile>
      <MobileWrapper>
        <Flex justifyContent="flex-start" flexWrap="wrap" mt="32px">
          <Box width={1}>
            <div onClick={() => { state.isModalOpen = true; render(); }}>
              <Button variant="primary">{t('Filtrar')}</Button>
            </div>
          </Box>
          <Box>
            <BtnExportReduced variant={typeLoading} onClick={getCsvData}>
              <div>
                <ExportWorksheet />
              </div>
            </BtnExportReduced>
            <CSVLink
              headers={CSVheader}
              data={state.csvData}
              filename={t('nomeArquivoMedicoesDeDispositivos')}
              separator=";"
              asyncOnClick
              enclosingCharacter={"'"}
              ref={csvLinkEl}
            />
          </Box>
        </Flex>
      </MobileWrapper>
      <CardWrapper>
        <DesktopWrapper>
          <Flex justifyContent="flex-start" flexWrap="wrap" mb={38}>
            <ContentDate>
              <DateLabel>{t('Data')}</DateLabel>
              <br />
              {!state.isModalOpen && (!state.multiDays) && (
                <SingleDatePicker
                  disabled={loading}
                  date={state.dateStart}
                  onDateChange={(value) => { setState({ dateStart: value, dateEnd: value }); }}
                  focused={state.focused}
                  onFocusChange={({ focused }) => setState({ focused })}
                  id="datepicker"
                  numberOfMonths={1}
                  isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
                />
              )}
              {!state.isModalOpen && (state.multiDays) && (
                <DateRangePicker
                  disabled={loading}
                  startDate={state.dateStart} // momentPropTypes.momentObj or null,
                  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                  endDate={state.dateEnd} // momentPropTypes.momentObj or null,
                  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) => { setState({ dateStart: startDate, dateEnd: startDate !== state.dateStart ? null : endDate }); }} // PropTypes.func.isRequired,
                  onFocusChange={(focused: 'endDate'|'startDate'|null) => setState({ focusedInput: focused })}
                  focusedInput={state.focusedInput}
                  noBorder
                  shouldCloseOnSelect
                  isOutsideRange={(d) => !d.isBefore(state.tomorrow)}
                />
              )}
              <StyledCalendarIcon color="#202370" />
            </ContentDate>
            <CheckboxLine>
              <Checkbox
                checked={state.multiDays}
                onClick={onMultidaysClick}
                style={{ marginLeft: '20px' }}
              />
              <Text>
                {t('multiplosDias')}
              </Text>
            </CheckboxLine>
            <Box width={[1, 1, 1 / 5]}>
              <Flex justifyContent="flex-end" flexWrap="wrap">
                <BtnExport variant={typeLoading} onClick={getCsvData}>
                  <div>
                    <ExportWorksheet />
                    <Text style={{ paddingLeft: '5px' }}>
                      {t('exportarPlanilha')}
                    </Text>
                  </div>
                </BtnExport>
                <CSVLink
                  headers={CSVheader}
                  data={state.csvData}
                  filename={t('nomeArquivoMedicoesDeDispositivos')}
                  separator=";"
                  asyncOnClick
                  enclosingCharacter={"'"}
                  ref={csvLinkEl}
                />
              </Flex>
            </Box>
          </Flex>
        </DesktopWrapper>
        {returnNoGraph}
        {(state.dateStart && state.dateEnd) && (
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1, 1, 2 / 3]} style={{ userSelect: 'none' }} minHeight="420px">
            <GraphWrapper>
              {state.isLoading && (
              <Overlay>
                <Loader />
              </Overlay>
              )}
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button type="button" className="btn update" onClick={zoomOut}>
                  Zoom Out
                </button>
              </div>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart
                  width={800}
                  height={500}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                  data={state.chartData.x.map((v, i) => i)}
                  onMouseDown={(e) => { setState({ refAreaLeft: e.activeLabel }); }}
                  onMouseMove={(e) => { (state.refAreaLeft != null) && setState({ refAreaRight: e.activeLabel }); }}
                  onMouseUp={zoom}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis allowDataOverflow type="number" dataKey={(i) => state.chartData.x[i]} tickFormatter={state.numDays && state.numDays > 1 ? tickXLabelFormaterDay : tickXLabelFormaterHour} ticks={state.xTicks} domain={state.xDomain} />
                  {state.numDays && state.numDays > 1 ? (<XAxis allowDataOverflow xAxisId="1" tickLine={false} axisLine={false} allowDuplicatedCategory={false} tick={renderQuarterTickHour} type="number" dataKey={(i) => state.chartData.x[i]} ticks={state.xTicks} domain={state.xDomain} />) : null}
                  <YAxis type="number" yAxisId="temp" allowDataOverflow dataKey="y" tick={<CustomTickTemp L1start={axisInfo.L1start} isFancoil={hwCfg.isFanCoil} manageAllClients={profile.manageAllClients} />} ticks={axisInfo.tempTicks} interval={0} domain={axisInfo.tempLimits}>
                    <Label
                      content={() => (
                        <CustomLabel angle="-90" x="-320" y="38" color="#656565">
                          {/* FEATURE DISPONIVEL APENAS PARA OS CLIENTES: BANCO DO BRASIL e DIEL ENERGIA */}
                          {((state.devInfo?.CLIENT_ID === 145 || state.devInfo?.CLIENT_ID === 1) && (state.devInfo.dac.AST_ROLE === 1 || state.devInfo.dac.AST_ROLE === 2)) ? `${t('temperatura')}/${t('corrente')}/${t('velocidade')}` : t('temperatura')}
                        </CustomLabel>
                      )}
                    />
                  </YAxis>
                  <YAxis
                    type="number"
                    yAxisId="press"
                    allowDataOverflow
                    dataKey="y"
                    tick={<CustomTickPress />}
                    label={{
                      value: 'Pressão', angle: -90, color: '#656565', position: 'insideTopRight', offset: 25,
                    }}
                    ticks={axisInfo.presTicks}
                    domain={axisInfo.presLimits}
                    orientation="right"
                  />
                  <Tooltip isAnimationActive={false} cursor={{ stroke: 'red', strokeWidth: 1 }} labelFormatter={tooltipXLabelFormater} formatter={toolTipFormater} />
                  {state.chartData && state.chartData.vars && state.chartData.vars.map((varInfo) => (
                    graphEnable[varInfo.id] && <Line name={varInfo.name} key={varInfo.name} yAxisId={varInfo.axisId} type={varInfo.type} dataKey={(i) => varInfo.y[i]} dot={false} stroke={varInfo.color} animationDuration={300} strokeDasharray={varInfo.strokeDasharray} />
                  ))}
                  {refArea}
                  {deltaTReferenceLines}
                  {temperatureReferenceLines}
                </LineChart>
              </ResponsiveContainer>
            </GraphWrapper>
          </Box>
          <Box width={[1, 1, 1, 1, 1 / 3]}>
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Tamb}
                onChange={() => { graphEnable.Tamb = !graphEnable.Tamb; insertVars(varsFix); render(); }}
                value={graphEnable.Tamb}
                color="primary"
              />
              <Text>
                {t('temperatura')}
                {` ${labelTempSelf()} `}
                [°C]
              </Text>
              <ColoredLine color={hwCfg.isFanCoil ? colors.Pink100 : colors.Green} />
            </CheckboxLine>
            <CheckboxLine>
              <Checkbox
                checked={
                  isFanCoilOrHeatExchanger
                    ? graphEnable.Tliq : graphEnable.Tsuc
                }
                onChange={() => {
                  if (isFanCoilOrHeatExchanger) {
                    graphEnable.Tliq = !graphEnable.Tliq; insertVars(varsFix); render();
                  } else {
                    graphEnable.Tsuc = !graphEnable.Tsuc; insertVars(varsFix); render();
                  }
                }}
                value={graphEnableTsucOrTliq()}
                color="primary"
              />
              <Text>
                {t('temperatura')}
                {' '}
                {temperatureLabel}
                {' '}
                [°C]
              </Text>
              <ColoredLine color={state.devInfo.dac && state.devInfo.dac.DAC_APPL === 'trocador-de-calor' ? colors.Red : colors.Blue300} />
            </CheckboxLine>
            <CheckboxLine>
              <Checkbox
                checked={
                  isFanCoilOrHeatExchanger
                    ? graphEnable.Tsuc : graphEnable.Tliq
                }
                onChange={() => {
                  if
                  (isFanCoilOrHeatExchanger) {
                    graphEnable.Tsuc = !graphEnable.Tsuc; insertVars(varsFix); render();
                  } else {
                    graphEnable.Tliq = !graphEnable.Tliq; insertVars(varsFix); render();
                  }
                }}
                value={graphEnableTscOrTliq()}
                color="primary"
              />
              <Text>
                {t('temperatura')}
                {' '}
                {isFanCoilOrHeatExchanger ? t('deSaidaAgua') : t('deLiquido')}
                {' '}
                [°C]
              </Text>
              <ColoredLine color={state.devInfo.dac && state.devInfo.dac.DAC_APPL === 'trocador-de-calor' ? colors.Blue300 : colors.Red} />
            </CheckboxLine>

            {lineChartFancoilHeatExchanger()}

            {(!profile.manageAllClients && state.withPsuc) && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Psuc}
                onChange={() => { graphEnable.Psuc = !graphEnable.Psuc; insertVars(varsFix); render(); }}
                value={graphEnable.Psuc}
                color="primary"
              />
              <Text>
                {t('pressaoSuccao')}
                [
                {isUsePsei}
                ]
              </Text>
              <ColoredDottedLine color={colors.Blue300} />
            </CheckboxLine>
            )}
            {(profile.manageAllClients && hwCfg.hasPsuc) && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Psuc}
                onChange={() => { graphEnable.Psuc = !graphEnable.Psuc; insertVars(varsFix); render(); }}
                value={graphEnable.Psuc}
                color="primary"
              />
              <Text>
                {t('pressaoSuccao')}
                [
                {isUsePsei}
                ]
              </Text>
              <ColoredDottedLine color={colors.Blue300} />
            </CheckboxLine>
            )}
            {hwCfg.hasPliq && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Pliq}
                onChange={() => { graphEnable.Pliq = !graphEnable.Pliq; insertVars(varsFix); render(); }}
                value={graphEnable.Pliq}
                color="primary"
              />
              <Text>
                {t('pressaoLiquido')}
                [
                {isUsePsei}
                ]
              </Text>
              <ColoredDottedLine color={colors.Red} />
            </CheckboxLine>
            )}
            {hwCfg.hasTsc && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Tsc}
                onChange={() => { graphEnable.Tsc = !graphEnable.Tsc; insertVars(varsFix); render(); }}
                value={graphEnable.Tsc}
                color="primary"
              />
              <Text>{`${t('subresfriamento')} [ΔTºC]`}</Text>
              <ColoredLine color={colors.Blue500} />
            </CheckboxLine>
            )}
            {hwCfg.hasTsh && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Tsh}
                onChange={() => { graphEnable.Tsh = !graphEnable.Tsh; insertVars(varsFix); render(); }}
                value={graphEnable.Tsh}
                color="primary"
              />
              <Text>{`${t('superaquecimento')} [ΔTºC]`}</Text>
              <ColoredLine color={colors.Pink400} />
            </CheckboxLine>
            )}
            {(!hwCfg.hasAutomation) && (!hwCfg.isFanCoil || !profile.manageAllClients) && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Lcmp}
                onChange={() => { graphEnable.Lcmp = !graphEnable.Lcmp; insertVars(varsFix); render(); }}
                value={graphEnable.Lcmp}
                color="primary"
              />
              <Text>{t('sinalComando')}</Text>
              <ColoredLine color={colors.Grey400} />
            </CheckboxLine>
            )}
            {verifyhasAutomationNotFancoil && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Levp}
                onChange={() => { graphEnable.Levp = !graphEnable.Levp; insertVars(varsFix); render(); }}
                value={graphEnable.Levp}
                color="primary"
              />
              <Text>{t('sinalComando')}</Text>
              <ColoredLine color={colors.Grey400} />
            </CheckboxLine>
            )}
            {(hwCfg.isFanCoil && profile.manageAllClients) && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.L1raw}
                onChange={() => { graphEnable.L1raw = !graphEnable.L1raw; insertVars(varsFix); render(); }}
                value={graphEnable.L1raw}
                color="primary"
              />
              <Text>{t('sinalComandoReal')}</Text>
              <ColoredLine color={colors.Grey400} />
            </CheckboxLine>
            )}
            {(hwCfg.isFanCoil && profile.manageAllClients) && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.L1fancoil}
                onChange={() => { graphEnable.L1fancoil = !graphEnable.L1fancoil; insertVars(varsFix); render(); }}
                value={graphEnable.L1fancoil}
                color="primary"
              />
              <Text>{t('sinalComandoSimulado')}</Text>
              <ColoredLine color={colors.Grey400} />
            </CheckboxLine>
            )}
            {(hwCfg.hasAutomation) && (
            <CheckboxLine>
              <Checkbox
                checked={graphEnable.Lcut}
                onChange={() => { graphEnable.Lcut = !graphEnable.Lcut; insertVars(varsFix); render(); }}
                value={graphEnable.Lcut}
                color="primary"
              />
              <Text>{t('bloqueioComando')}</Text>
              <ColoredLine color={colors.Orange600} />
            </CheckboxLine>
            )}
            {lineLimitsTemperature()}
            {/* FEATURE DISPONIVEL APENAS PARA OS CLIENTES: BANCO DO BRASIL e DIEL ENERGIA */}
            {verifyClientsFeature && (
              <CheckboxLine>
                <Checkbox
                  checked={graphEnable.Icomp}
                  onChange={() => { graphEnable.Icomp = !graphEnable.Icomp; insertVars(varsFix); render(); }}
                  value={graphEnable.Icomp}
                  color="primary"
                />
                <Text>
                  {t('correnteDoCompressor')}
                  {' '}
                  [A]
                </Text>
                <ColoredLine color={colors.Green_v2} />
              </CheckboxLine>
            )}
            {featureClientsEvaporatorAndInsufflation()}
          </Box>
        </Flex>
        )}
        {(profile.manageAllClients && (state.dateStart && state.dateEnd))
        && (
          <div>
            <FaultsChart
              faultsChart={state.faultsChart}
              withFaults={state.withFaults}
              onFaultsCheck={() => { state.withFaults = !state.withFaults; insertVars(varsFix); render(); }}
              xTicks={state.xTicks}
              tickXLabelFormaterDay={tickXLabelFormaterDay}
              tickXLabelFormaterHour={tickXLabelFormaterHour}
              renderQuarterTickHour={renderQuarterTickHour}
              numDays={state.numDays}
              xDomain={state.xDomain}
            />
          </div>
        )}
      </CardWrapper>
    </>
  );
}

function FaultsChart({
  faultsChart, withFaults, onFaultsCheck, xTicks, tickXLabelFormaterDay, tickXLabelFormaterHour, renderQuarterTickHour, numDays, xDomain,
}) {
  return (
    <>
      <CheckboxLine style={{ marginBottom: '10px' }}>
        <Checkbox
          checked={withFaults}
          onChange={onFaultsCheck}
          color="primary"
        />
        <Text>{t('analisarFalhasRepetinas')}</Text>
      </CheckboxLine>
      {(withFaults)
        && (
        <Flex flexWrap="wrap" alignItems="right">
          <Box width={[1, 1, 1, 1]} minHeight="420px">
            <div style={{ width: '100%', height: `${600}px` }}>
              <ResponsiveContainer>
                <ScatterChart
                  height={600}
                  margin={{
                    top: 5, right: 30, left: 350, bottom: 5,
                  }}
                  line={false}
                >
                  <Tooltip
                    content={(props) => {
                      const { active, payload } = props;

                      if (active && payload && payload.length === 2) {
                        return (
                          <div
                            style={{
                              padding: 10,
                              border: `1px solid ${colors.Grey100}`,
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            {`${payload[0].payload.time.local().format('DD/MM - HH:mm:SS')} - ${payload[1].payload.desc}`}
                          </div>
                        );
                      }
                      return null;
                    }}
                    isAnimationActive={false}
                  />
                  <CartesianGrid />
                  <XAxis
                    type="number"
                    name="time"
                    dataKey="x"
                    ticks={xTicks}
                    allowDecimals={false}
                    allowDataOverflow
                    tickFormatter={numDays && numDays > 1 ? tickXLabelFormaterDay : tickXLabelFormaterHour}
                    domain={xDomain}
                  />
                  {numDays && numDays > 1 ? (<XAxis allowDataOverflow xAxisId="1" tickLine={false} axisLine={false} allowDuplicatedCategory={false} tick={renderQuarterTickHour} type="number" dataKey="x" ticks={xTicks} domain={['dataMin', 'dataMax']} />) : null}
                  <YAxis
                    type="number"
                    name="left"
                    yAxisId="left"
                    dataKey="y"
                    allowDataOverflow
                    tick={<CustomYTick boolTicksNames={faultsChart.boolTicksNames} />}
                    ticks={faultsChart.leftTicks}
                    interval={0}
                    domain={faultsChart.leftLimits}
                  />
                  {faultsChart.lines.map((line) => (
                    <Scatter
                      key={line.lineId}
                      yAxisId="left"
                      strokeWidth={line.isDet ? 10 : 1}
                      shape={(props) => (
                        <Dot
                          {...props}
                          r={7}
                        />
                      )}
                      data={line.data}
                      fill={line.color}
                      isAnimationActive={false}
                    />
                  ))}
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </Box>
        </Flex>
        )}
    </>
  );
}

function CustomTickPress(props) {
  const {
    x, y, payload, anchor,
  } = props;

  return (
    <g transform={`translate(${x + 3},${anchor ? y : y - 12})`}>
      <text x={0} y={0} dy={16} textAnchor={anchor || 'start'} fill="#666" fontSize="10px">
        {payload.value}
      </text>
    </g>
  );
}

function CustomTickTemp(props) {
  const {
    x, y, payload, anchor, L1start, isFancoil, manageAllClients,
  } = props;

  let label = payload.value;

  if (payload.value < (L1start - 5)) {
    // label = ((payload.value % 5) > -2.5) ? 'LIBERADO' : 'BLOQUEADO';
    if (isFancoil
      && manageAllClients
      && (payload.value < (L1start - 7) && payload.value > (L1start - 10))) {
      label = ((payload.value % 5) > -2.5) ? t('ligado') : t('desligado');
    } else {
      label = ((payload.value % 5) > -2.5) ? t('liberado') : t('bloqueado');
    }
  } else if (payload.value < L1start) {
    label = ((payload.value % 5) > -2.5) ? t('ligado') : t('desligado');
  }

  return (
    <g transform={`translate(${x - 3},${anchor ? y : y - 12})`}>
      <text x={0} y={0} dy={16} textAnchor={anchor || 'end'} fill="#666" fontSize="10px">
        {label}
      </text>
    </g>
  );
}

const CustomYTick = ({
  x, y, payload, anchor, boolTicksNames, right,
}: any) => {
  let text = String(payload.value);
  if (boolTicksNames && boolTicksNames[text] != null) {
    text = boolTicksNames[text];
  } else if ((payload.value < -0.1) && ((payload.value % 5) !== 0)) {
    text = ((payload.value % 5) > -2.5) ? t('ligado') : t('desligado');
  }
  if (right) {
    return (
      <g transform={`translate(${x + 3},${anchor ? y : y - 12})`}>
        <text x={0} y={0} dy={8} textAnchor={anchor || 'start'} fill="#666" fontSize="10px" />
      </g>
    );
  }
  return (
    <g transform={`translate(${x - 3},${anchor ? y : y - 12})`}>
      <text x={0} y={0} dy={8} textAnchor={anchor || 'end'} fill="#666" fontSize="10px">
        {text}
      </text>
    </g>
  );
};

const CustomShape = ({ x, y }: any) => (
  <g>
    <text x={x + 5} y={y + 10} fill="rgba(0,0,0,0)" fontSize="10px">
      M
    </text>
  </g>
);

export const ContentDate = styled.div`
  position: relative;
  border: 1px solid ${colors.GreyLight};
  border-radius: 5px;
  min-height: 50px;
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colors.BlueSecondary};
  }
  .react-datepicker__triangle {
    left: -130px !important;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-bottom-color: white;
  }
  .react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
      input[type="text"] {
        border: none;
        font-size: 12px;
        outline: none;
        line-height: 19px;
        padding: 0px 40px 0px 14px;
        color: #464555;
      }
    }
  }
  .SingleDatePicker {
    display: block;
    position: initial;
  }
  .SingleDatePickerInput {
    display: block;
    position: relative;
    border: none;
    .DateInput {
      display: block;
      position: relative;
      width: 100%;
      .DateInput_input {
        outline: none;
        font-size: 12px;
        line-height: 19px;
        padding: 0px 40px 0px 14px;
        color: ${colors.Grey400};
        width: 100%;
      }
      .DateInput_input__focused {
        border: none;
      }
    }
  }
  .DateRangePickerInput {
    .DateRangePickerInput_arrow {
      width: 60px;
    }
    .DateInput {
      .DateInput_input {
        outline: none;
        font-size: 12px;
        line-height: 19px;
        padding: 0px 40px 0px 14px;
        color: ${colors.Grey400};
      }
      .DateInput_input__focused {
        border: none;
      }
    }
  }
  .DateInput_fang {
    z-index: 1;
  }
  .SingleDatePicker_picker {
    width: 100%;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active {
    background: ${colors.BlueSecondary};
    border: 1px double ${colors.BlueSecondary};
    color: ${colors.White};
  }
  .CalendarDay__selected:hover {
    background: ${colors.BlueSecondary};
    border: 1px double ${colors.BlueSecondary};
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid ${colors.BlueSecondary};
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid ${colors.BlueSecondary};
  }
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  top: 17px;
  right: 16px;
`;

export const DateLabel = styled.span`
  transition: all 0.2s;
  margin-top: -6px;
  margin-left: 16px;
  margin-right: 16px;
  color: ${colors.Blue700};
  font-size: 11px;
  font-weight: bold;
`;

export const BtnExport = styled.div`
  cursor: pointer;
  color: #363BC4;
  padding: 14px 20px;
  border: 1px solid ${colors.GreyLight};
  margin-left: 10px;
  font-weight: 400;
  border-radius: 12px;
  height: 41px;
  display: flex;
  text-align: center;
  align-items: center;
  :hover {
    color: ${colors.White};
    background-color: #363BC4;
    ${Text} {
      color: ${colors.White};
    }
  }
`;

export const BtnExportReduced = styled.div`
  cursor: pointer;
  color: #363BC4;
  padding: 14px 20px;
  border: 1px solid ${colors.GreyLight};
  margin-left: 10px;
  border-radius: 12px;
  height: 1px;
  display: flex;
  text-align: center;
  align-items: center;
  :hover {
    color: ${colors.White};
    background-color: #363BC4;
  }
`;

export const ExportWorksheet = styled(ExportWorksheetIcon)(
  () => `
  padding-left: 10px;
  color: #363BC4;
`,
);
