type FilterIconProps = {
  color?: string;
  style?: {};
  heigth?: string;
  width?: string;
}

export const FilterIcon = ({
  color = '#363BC4', width = '16', heigth = '15', ...props
}: FilterIconProps): JSX.Element => (
  <svg {...props} width={width} height={heigth} className="filter" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.79652 2.84785C1.27246 2.26214 1.01043 1.96928 1.00055 1.72039C0.991961 1.50417 1.08487 1.29636 1.25173 1.15859C1.44381 1 1.83678 1 2.62272 1H12.9073C13.6932 1 14.0862 1 14.2783 1.15859C14.4451 1.29636 14.538 1.50417 14.5295 1.72039C14.5196 1.96928 14.2575 2.26214 13.7335 2.84786L9.77966 7.26682C9.6752 7.38358 9.62296 7.44195 9.58572 7.50839C9.55269 7.56732 9.52845 7.63076 9.51378 7.6967C9.49723 7.77104 9.49723 7.84938 9.49723 8.00605V11.711C9.49723 11.8465 9.49723 11.9142 9.47538 11.9728C9.45607 12.0246 9.42466 12.071 9.38377 12.1081C9.3375 12.1502 9.2746 12.1753 9.14878 12.2257L6.79295 13.168C6.53828 13.2699 6.41095 13.3208 6.30873 13.2996C6.21934 13.281 6.1409 13.2279 6.09045 13.1518C6.03277 13.0648 6.03277 12.9276 6.03277 12.6533V8.00605C6.03277 7.84938 6.03277 7.77104 6.01622 7.6967C6.00155 7.63076 5.97731 7.56732 5.94428 7.50839C5.90704 7.44195 5.8548 7.38358 5.75034 7.26682L1.79652 2.84785Z" stroke={color} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
