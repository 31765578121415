type PowerIconProps = {
  color?: string;
}

export const PowerIcon = ({ color }: PowerIconProps): JSX.Element => (
  <svg width="139" height="150" viewBox="0 0 139 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.3181 0H71.8678V31.0785H66.3181V0ZM0 80.7488C0 118.764 31.0786 149.843 69.0943 149.843C107.11 149.843 137.911 118.764 138.189 81.0262C138.189 49.9477 117.377 22.754 87.6859 14.4294L86.2985 19.7016C113.492 27.1938 132.639 52.4451 132.639 80.7488C132.639 115.712 104.058 144.293 69.0943 144.293C34.1309 144.293 5.54974 115.712 5.54974 80.7488C5.54974 52.4451 24.4189 27.4712 51.6126 19.7016L50.2251 14.4294C20.534 22.754 0 49.9477 0 80.7488Z"
      fill={color}
    />
  </svg>
);
