interface MoreIconProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const MoreIcon: React.FC<MoreIconProps> = ({
  color, style, className, ...rest
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15" fill="none" style={style} className={className} {...rest}>
    <path d="M0 13.5C0 13.1022 0.158035 12.7206 0.43934 12.4393C0.720644 12.158 1.10218 12 1.5 12C1.89782 12 2.27936 12.158 2.56066 12.4393C2.84196 12.7206 3 13.1022 3 13.5C3 13.8978 2.84196 14.2794 2.56066 14.5607C2.27936 14.842 1.89782 15 1.5 15C1.10218 15 0.720644 14.842 0.43934 14.5607C0.158035 14.2794 0 13.8978 0 13.5ZM0 7.5C0 7.10218 0.158035 6.72064 0.43934 6.43934C0.720644 6.15804 1.10218 6 1.5 6C1.89782 6 2.27936 6.15804 2.56066 6.43934C2.84196 6.72064 3 7.10218 3 7.5C3 7.89783 2.84196 8.27936 2.56066 8.56066C2.27936 8.84196 1.89782 9 1.5 9C1.10218 9 0.720644 8.84196 0.43934 8.56066C0.158035 8.27936 0 7.89783 0 7.5ZM0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0C1.89782 0 2.27936 0.158035 2.56066 0.43934C2.84196 0.720644 3 1.10218 3 1.5C3 1.89782 2.84196 2.27936 2.56066 2.56066C2.27936 2.84196 1.89782 3 1.5 3C1.10218 3 0.720644 2.84196 0.43934 2.56066C0.158035 2.27936 0 1.89782 0 1.5Z" fill={color ?? '#363BC4'} />
  </svg>
);
