type PeopleIconProps = {
  color?: string;
}

export const PeopleIcon = ({ color, ...props }: PeopleIconProps): JSX.Element => (
  <svg {...props} width="20" height="21" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.483 4.36788C15.5927 5.06365 16.3708 6.24104 16.5125 7.60772C16.965 7.81917 17.4673 7.94078 17.9999 7.94078C19.944 7.94078 21.5197 6.36504 21.5197 4.42119C21.5197 2.47705 19.944 0.901314 17.9999 0.901314C16.0743 0.901913 14.5123 2.4495 14.483 4.36788ZM12.177 11.5748C14.1212 11.5748 15.6969 9.99873 15.6969 8.05489C15.6969 6.11105 14.1209 4.53531 12.177 4.53531C10.2332 4.53531 8.65653 6.11135 8.65653 8.05519C8.65653 9.99903 10.2332 11.5748 12.177 11.5748ZM13.6701 11.8147H10.6833C8.19828 11.8147 6.17656 13.8367 6.17656 16.3218V19.9743L6.18585 20.0315L6.43744 20.1103C8.80899 20.8513 10.8693 21.0984 12.5652 21.0984C15.8775 21.0984 17.7974 20.154 17.9157 20.0938L18.1508 19.9749H18.176V16.3218C18.1769 13.8367 16.1551 11.8147 13.6701 11.8147ZM19.4935 8.18098H16.5298C16.4978 9.36676 15.9916 10.4345 15.191 11.2025C17.3999 11.8593 19.0161 13.9077 19.0161 16.3271V17.4527C21.9423 17.3455 23.6286 16.5161 23.7397 16.4604L23.9748 16.3412H24V12.6875C24 10.2027 21.9783 8.18098 19.4935 8.18098ZM6.00075 7.94137C6.68933 7.94137 7.32999 7.7404 7.87271 7.39806C8.04523 6.27279 8.64845 5.28948 9.51015 4.61977C9.51374 4.55388 9.52003 4.48858 9.52003 4.42209C9.52003 2.47795 7.94399 0.902212 6.00075 0.902212C4.05631 0.902212 2.48087 2.47795 2.48087 4.42209C2.48087 6.36534 4.05631 7.94137 6.00075 7.94137ZM9.16181 11.2025C8.36511 10.4384 7.86073 9.37634 7.82389 8.19776C7.71397 8.18967 7.60524 8.18098 7.49322 8.18098H4.50678C2.02171 8.18098 0 10.2027 0 12.6875V16.3406L0.00928491 16.3969L0.260876 16.4763C2.16338 17.0702 3.86133 17.344 5.33613 17.4303V16.3271C5.33673 13.9077 6.9523 11.8599 9.16181 11.2025Z"
      fill={color || '#5D5D5D'}
    />
  </svg>
);
