import styled from 'styled-components';

type ClientsIconProps = {
  color?: string;
}

export const ClientsIcon = ({ color, ...props }: ClientsIconProps): JSX.Element => (
  <svg width="25px" {...props} viewBox="0 0 5.5 3.3" xmlns="http://www.w3.org/2000/svg">
    <CPath
      color={color}
      d="m 4.9335958,0.81253308 c 0,0.28765332 -0.2331895,0.52084252 -0.5208425,0.52084252 -0.2876535,10e-8 -0.5208428,-0.2331891 -0.5208428,-0.52084252 0,-0.2876535 0.2331893,-0.5208427 0.5208428,-0.5208425 0.287653,0 0.5208425,0.2331891 0.5208425,0.5208425 z M 4.0105439,2.6009512 C 3.966669,2.2075351 3.7378571,2.0656344 3.4999915,1.9360455 3.5741192,1.6697408 3.7514661,1.5335822 4.0075756,1.4967328 l 0.1543529,0.00891 0.2434032,0.4452489 0.2463711,-0.4452489 H 4.8298024 C 5.0848692,1.5568868 5.2683441,1.6860763 5.333864,1.997405 l 5.331e-4,0.1523654 C 4.9037061,2.4811455 4.4676024,2.7083572 4.0138479,2.8501175 Z M 1.8019287,2.8704506 1.8050553,2.5712632 C 1.8662302,2.2642323 2.04984,2.0497135 2.3914815,1.9724558 l 0.1497192,-0.012668 c 0.1825012,0.1471641 0.3876374,0.1912137 0.6411589,0.014841 L 3.3638572,1.9740957 C 3.5864319,2.0299047 3.8619571,2.2248 3.937799,2.5979657 L 3.933549,2.8769133 C 3.2150557,3.0892563 2.4939399,3.072853 1.8019286,2.8704542 Z M 0.33573292,2.1141406 C 0.40876762,1.7629668 0.56595622,1.4941169 1.0006383,1.4967284 1.1669039,1.6151771 1.3656743,1.6134067 1.5636021,1.5023046 1.8509147,1.4890479 2.0757228,1.5774896 2.1820328,1.9419779 1.9214784,2.0903433 1.7797755,2.2844196 1.7249103,2.5118961 l -0.0056,0.3383396 C 1.2249704,2.6971383 0.76427512,2.447039 0.33573322,2.1141406 M 3.5771685,1.6332715 3.4228155,0.98320778 c -0.2277226,-0.5324019 -0.9181295,-0.5147568 -1.122028,0 L 2.1404978,1.6332715 h 0.3324528 c 0.1943428,0.2219685 0.5912832,0.209184 0.7836385,0 z M 0.61477722,1.4611087 0.74538372,0.65965998 C 0.98947402,0.13189508 1.6113844,0.18675718 1.8258551,0.65372318 L 1.9564615,1.4611084 1.6833754,1.3720584 1.6180722,1.1998955 C 1.4004068,1.3880326 1.1668744,1.3857674 0.91754682,1.1939586 l -0.0237468,0.1780996 z"
    />
  </svg>
);

const CPath = styled.path`
  fill: ${({ color }) => color || '#000000'};
  fill-opacity: 1;
  stroke: none;
  stroke-width: 0.0237061;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1;
`;
