type HealthDisabledIconProps = {
  color?: string;
}

export const HealthDisabledIcon = ({ color = '#4e4e4e' }: HealthDisabledIconProps): JSX.Element => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M 9.8552859,0.00110613 C 15.42883,-0.08078487 19.936943,4.3956999 19.999334,9.87673 20.062864,15.459362 15.572476,19.950667 10.10103,19.999589 4.5633609,20.049079 0.0984989,15.620594 0.0016489,10.191324 -0.0988181,4.5637402 4.4056059,0.06938643 9.8552859,0.00110613 Z M 3.8871069,5.6895266 c -1.806848,2.378203 -2.126967,6.5438534 0.702418,9.4902684 2.924888,3.04582 7.2295831,2.813969 9.7234621,0.935981 C 10.837977,12.640643 7.3619019,9.164446 3.8871069,5.6895266 Z M 16.11487,14.311242 C 18.079826,11.689347 18.18235,7.2363287 14.949254,4.3762017 11.933329,1.7081507 7.8874229,2.1934061 5.6932439,3.8896714 9.1666229,7.363031 12.641349,10.837669 16.11487,14.311242 Z"
    />
  </svg>
);
