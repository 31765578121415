type MonitoringIconProps = {
  color?: string;
}

export const MonitoringIcon = ({ color = '#363BC4', ...props }: MonitoringIconProps): JSX.Element => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5165 13.1691H3.48262C3.04455 13.1684 2.62461 12.9941 2.31484 12.6843C2.00507 12.3745 1.83075 11.9546 1.83008 11.5165V3.48262C1.83075 3.04455 2.00507 2.62461 2.31484 2.31484C2.62461 2.00507 3.04455 1.83075 3.48262 1.83008H11.5165C11.9546 1.83075 12.3745 2.00507 12.6843 2.31484C12.9941 2.62461 13.1684 3.04455 13.1691 3.48262V11.5165C13.1684 11.9546 12.9941 12.3745 12.6843 12.6843C12.3745 12.9941 11.9546 13.1684 11.5165 13.1691V13.1691ZM3.48262 3.10126C3.38148 3.10126 3.28448 3.14144 3.21296 3.21296C3.14144 3.28448 3.10126 3.38148 3.10126 3.48262V11.5165C3.10126 11.6177 3.14144 11.7147 3.21296 11.7862C3.28448 11.8577 3.38148 11.8979 3.48262 11.8979H11.5165C11.6177 11.8979 11.7147 11.8577 11.7862 11.7862C11.8577 11.7147 11.8979 11.6177 11.8979 11.5165V3.48262C11.8979 3.38148 11.8577 3.28448 11.7862 3.21296C11.7147 3.14144 11.6177 3.10126 11.5165 3.10126H3.48262Z" fill={color} />
    <path d="M10.0426 10.6782H4.95786C4.78929 10.6782 4.62762 10.6112 4.50843 10.492C4.38923 10.3728 4.32227 10.2112 4.32227 10.0426V4.95786C4.32227 4.78929 4.38923 4.62762 4.50843 4.50843C4.62762 4.38923 4.78929 4.32227 4.95786 4.32227H10.0426C10.2112 4.32227 10.3728 4.38923 10.492 4.50843C10.6112 4.62762 10.6782 4.78929 10.6782 4.95786V10.0426C10.6782 10.2112 10.6112 10.3728 10.492 10.492C10.3728 10.6112 10.2112 10.6782 10.0426 10.6782ZM5.59345 9.40701H9.40701V5.59345H5.59345V9.40701Z" fill={color} />
    <path d="M3.6102 3.10169C3.44163 3.10169 3.27997 3.03473 3.16077 2.91553C3.04157 2.79634 2.97461 2.63467 2.97461 2.4661V0.635593C2.97461 0.467023 3.04157 0.305358 3.16077 0.186161C3.27997 0.0669641 3.44163 0 3.6102 0C3.77877 0 3.94044 0.0669641 4.05963 0.186161C4.17883 0.305358 4.2458 0.467023 4.2458 0.635593V2.4661C4.2458 2.63467 4.17883 2.79634 4.05963 2.91553C3.94044 3.03473 3.77877 3.10169 3.6102 3.10169Z" fill={color} />
    <path d="M6.202 3.10169C6.03343 3.10169 5.87176 3.03473 5.75257 2.91553C5.63337 2.79634 5.56641 2.63467 5.56641 2.4661V0.635593C5.56641 0.467023 5.63337 0.305358 5.75257 0.186161C5.87176 0.0669641 6.03343 0 6.202 0C6.37057 0 6.53223 0.0669641 6.65143 0.186161C6.77063 0.305358 6.83759 0.467023 6.83759 0.635593V2.4661C6.83759 2.63467 6.77063 2.79634 6.65143 2.91553C6.53223 3.03473 6.37057 3.10169 6.202 3.10169Z" fill={color} />
    <path d="M8.79575 3.10169C8.62718 3.10169 8.46551 3.03473 8.34632 2.91553C8.22712 2.79634 8.16016 2.63467 8.16016 2.4661V0.635593C8.16016 0.467023 8.22712 0.305358 8.34632 0.186161C8.46551 0.0669641 8.62718 0 8.79575 0C8.96432 0 9.12599 0.0669641 9.24518 0.186161C9.36438 0.305358 9.43134 0.467023 9.43134 0.635593V2.4661C9.43134 2.63467 9.36438 2.79634 9.24518 2.91553C9.12599 3.03473 8.96432 3.10169 8.79575 3.10169Z" fill={color} />
    <path d="M11.3895 3.10169C11.2209 3.10169 11.0593 3.03473 10.9401 2.91553C10.8209 2.79634 10.7539 2.63467 10.7539 2.4661V0.635593C10.7539 0.467023 10.8209 0.305358 10.9401 0.186161C11.0593 0.0669641 11.2209 0 11.3895 0C11.5581 0 11.7197 0.0669641 11.8389 0.186161C11.9581 0.305358 12.0251 0.467023 12.0251 0.635593V2.4661C12.0251 2.63467 11.9581 2.79634 11.8389 2.91553C11.7197 3.03473 11.5581 3.10169 11.3895 3.10169Z" fill={color} />
    <path d="M3.6102 15.0001C3.44163 15.0001 3.27997 14.9332 3.16077 14.814C3.04157 14.6948 2.97461 14.5331 2.97461 14.3645V12.534C2.97461 12.3655 3.04157 12.2038 3.16077 12.0846C3.27997 11.9654 3.44163 11.8984 3.6102 11.8984C3.77877 11.8984 3.94044 11.9654 4.05963 12.0846C4.17883 12.2038 4.2458 12.3655 4.2458 12.534V14.3645C4.2458 14.5331 4.17883 14.6948 4.05963 14.814C3.94044 14.9332 3.77877 15.0001 3.6102 15.0001Z" fill={color} />
    <path d="M6.202 15.0001C6.03343 15.0001 5.87176 14.9332 5.75257 14.814C5.63337 14.6948 5.56641 14.5331 5.56641 14.3645V12.534C5.56641 12.3655 5.63337 12.2038 5.75257 12.0846C5.87176 11.9654 6.03343 11.8984 6.202 11.8984C6.37057 11.8984 6.53223 11.9654 6.65143 12.0846C6.77063 12.2038 6.83759 12.3655 6.83759 12.534V14.3645C6.83759 14.5331 6.77063 14.6948 6.65143 14.814C6.53223 14.9332 6.37057 15.0001 6.202 15.0001Z" fill={color} />
    <path d="M8.79575 15.0001C8.62718 15.0001 8.46551 14.9332 8.34632 14.814C8.22712 14.6948 8.16016 14.5331 8.16016 14.3645V12.534C8.16016 12.3655 8.22712 12.2038 8.34632 12.0846C8.46551 11.9654 8.62718 11.8984 8.79575 11.8984C8.96432 11.8984 9.12599 11.9654 9.24518 12.0846C9.36438 12.2038 9.43134 12.3655 9.43134 12.534V14.3645C9.43134 14.5331 9.36438 14.6948 9.24518 14.814C9.12599 14.9332 8.96432 15.0001 8.79575 15.0001Z" fill={color} />
    <path d="M11.3895 15.0001C11.2209 15.0001 11.0593 14.9332 10.9401 14.814C10.8209 14.6948 10.7539 14.5331 10.7539 14.3645V12.534C10.7539 12.3655 10.8209 12.2038 10.9401 12.0846C11.0593 11.9654 11.2209 11.8984 11.3895 11.8984C11.5581 11.8984 11.7197 11.9654 11.8389 12.0846C11.9581 12.2038 12.0251 12.3655 12.0251 12.534V14.3645C12.0251 14.5331 11.9581 14.6948 11.8389 14.814C11.7197 14.9332 11.5581 15.0001 11.3895 15.0001Z" fill={color} />
    <path d="M14.3645 4.2458H12.534C12.3655 4.2458 12.2038 4.17883 12.0846 4.05963C11.9654 3.94044 11.8984 3.77877 11.8984 3.6102C11.8984 3.44163 11.9654 3.27997 12.0846 3.16077C12.2038 3.04157 12.3655 2.97461 12.534 2.97461H14.3645C14.5331 2.97461 14.6948 3.04157 14.814 3.16077C14.9332 3.27997 15.0001 3.44163 15.0001 3.6102C15.0001 3.77877 14.9332 3.94044 14.814 4.05963C14.6948 4.17883 14.5331 4.2458 14.3645 4.2458Z" fill={color} />
    <path d="M14.3645 6.83857H12.534C12.3655 6.83857 12.2038 6.77161 12.0846 6.65241C11.9654 6.53321 11.8984 6.37155 11.8984 6.20298C11.8984 6.03441 11.9654 5.87274 12.0846 5.75354C12.2038 5.63435 12.3655 5.56738 12.534 5.56738H14.3645C14.5331 5.56738 14.6948 5.63435 14.814 5.75354C14.9332 5.87274 15.0001 6.03441 15.0001 6.20298C15.0001 6.37155 14.9332 6.53321 14.814 6.65241C14.6948 6.77161 14.5331 6.83857 14.3645 6.83857Z" fill={color} />
    <path d="M14.3645 9.43232H12.534C12.3655 9.43232 12.2038 9.36536 12.0846 9.24616C11.9654 9.12696 11.8984 8.9653 11.8984 8.79673C11.8984 8.62816 11.9654 8.46649 12.0846 8.34729C12.2038 8.2281 12.3655 8.16113 12.534 8.16113H14.3645C14.5331 8.16113 14.6948 8.2281 14.814 8.34729C14.9332 8.46649 15.0001 8.62816 15.0001 8.79673C15.0001 8.9653 14.9332 9.12696 14.814 9.24616C14.6948 9.36536 14.5331 9.43232 14.3645 9.43232Z" fill={color} />
    <path d="M14.3645 12.0251H12.534C12.3655 12.0251 12.2038 11.9581 12.0846 11.8389C11.9654 11.7197 11.8984 11.5581 11.8984 11.3895C11.8984 11.2209 11.9654 11.0593 12.0846 10.9401C12.2038 10.8209 12.3655 10.7539 12.534 10.7539H14.3645C14.5331 10.7539 14.6948 10.8209 14.814 10.9401C14.9332 11.0593 15.0001 11.2209 15.0001 11.3895C15.0001 11.5581 14.9332 11.7197 14.814 11.8389C14.6948 11.9581 14.5331 12.0251 14.3645 12.0251Z" fill={color} />
    <path d="M2.4661 4.2458H0.635593C0.467023 4.2458 0.305358 4.17883 0.186161 4.05963C0.0669641 3.94044 0 3.77877 0 3.6102C0 3.44163 0.0669641 3.27997 0.186161 3.16077C0.305358 3.04157 0.467023 2.97461 0.635593 2.97461H2.4661C2.63467 2.97461 2.79634 3.04157 2.91553 3.16077C3.03473 3.27997 3.10169 3.44163 3.10169 3.6102C3.10169 3.77877 3.03473 3.94044 2.91553 4.05963C2.79634 4.17883 2.63467 4.2458 2.4661 4.2458Z" fill={color} />
    <path d="M2.4661 6.83857H0.635593C0.467023 6.83857 0.305358 6.77161 0.186161 6.65241C0.0669641 6.53321 0 6.37155 0 6.20298C0 6.03441 0.0669641 5.87274 0.186161 5.75354C0.305358 5.63435 0.467023 5.56738 0.635593 5.56738H2.4661C2.63467 5.56738 2.79634 5.63435 2.91553 5.75354C3.03473 5.87274 3.10169 6.03441 3.10169 6.20298C3.10169 6.37155 3.03473 6.53321 2.91553 6.65241C2.79634 6.77161 2.63467 6.83857 2.4661 6.83857Z" fill={color} />
    <path d="M2.4661 9.43232H0.635593C0.467023 9.43232 0.305358 9.36536 0.186161 9.24616C0.0669641 9.12696 0 8.9653 0 8.79673C0 8.62816 0.0669641 8.46649 0.186161 8.34729C0.305358 8.2281 0.467023 8.16113 0.635593 8.16113H2.4661C2.63467 8.16113 2.79634 8.2281 2.91553 8.34729C3.03473 8.46649 3.10169 8.62816 3.10169 8.79673C3.10169 8.9653 3.03473 9.12696 2.91553 9.24616C2.79634 9.36536 2.63467 9.43232 2.4661 9.43232Z" fill={color} />
    <path d="M2.4661 12.0251H0.635593C0.467023 12.0251 0.305358 11.9581 0.186161 11.8389C0.0669641 11.7197 0 11.5581 0 11.3895C0 11.2209 0.0669641 11.0593 0.186161 10.9401C0.305358 10.8209 0.467023 10.7539 0.635593 10.7539H2.4661C2.63467 10.7539 2.79634 10.8209 2.91553 10.9401C3.03473 11.0593 3.10169 11.2209 3.10169 11.3895C3.10169 11.5581 3.03473 11.7197 2.91553 11.8389C2.79634 11.9581 2.63467 12.0251 2.4661 12.0251Z" fill={color} />
  </svg>
);
