type WarningIconProps = {
  size?: string;
}

export const SmallWarningIcon = ({
  size = '20px', ...props
}: WarningIconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 12.2V9M9 5.8H9.008M1 6.21819V11.7818C1 11.9775 1 12.0753 1.0221 12.1674C1.0417 12.249 1.07402 12.327 1.11789 12.3986C1.16736 12.4794 1.23654 12.5485 1.3749 12.6869L5.3131 16.6251C5.45146 16.7635 5.52064 16.8326 5.60137 16.8821C5.67295 16.926 5.75099 16.9583 5.83261 16.9779C5.92468 17 6.02252 17 6.21819 17H11.7818C11.9775 17 12.0753 17 12.1674 16.9779C12.249 16.9583 12.327 16.926 12.3986 16.8821C12.4794 16.8326 12.5485 16.7635 12.6869 16.6251L16.6251 12.6869C16.7635 12.5485 16.8326 12.4794 16.8821 12.3986C16.926 12.327 16.9583 12.249 16.9779 12.1674C17 12.0753 17 11.9775 17 11.7818V6.21819C17 6.02252 17 5.92468 16.9779 5.83261C16.9583 5.75099 16.926 5.67295 16.8821 5.60137C16.8326 5.52064 16.7635 5.45146 16.6251 5.3131L12.6869 1.3749C12.5485 1.23654 12.4794 1.16736 12.3986 1.11789C12.327 1.07402 12.249 1.0417 12.1674 1.0221C12.0753 1 11.9775 1 11.7818 1H6.21819C6.02252 1 5.92468 1 5.83261 1.0221C5.75099 1.0417 5.67295 1.07402 5.60137 1.11789C5.52064 1.16736 5.45146 1.23654 5.3131 1.3749L1.3749 5.3131C1.23654 5.45146 1.16736 5.52064 1.11789 5.60137C1.07402 5.67295 1.0417 5.75099 1.0221 5.83261C1 5.92468 1 6.02252 1 6.21819Z" stroke="#FFBE16" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
