type IconProps = {
  color?: string
  cursor?: string
}
export const NewApiIcon = ({ color = '#363BC4', ...props }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={17}
    fill="none"
    style={props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.5 1.202V4.3c0 .42 0 .63.082.79a.75.75 0 0 0 .328.328c.16.082.37.082.79.082h3.098M8.5 12.625l1.875-1.875L8.5 8.875m-3 0L3.625 10.75 5.5 12.625M13 6.991V12.4c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.984.984-.48.245-1.11.245-2.371.245H4.6c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 0 1-.984-.984C1 14.291 1 13.662 1 12.4V4.6c0-1.26 0-1.89.245-2.371a2.25 2.25 0 0 1 .984-.984C2.709 1 3.339 1 4.6 1h2.409c.55 0 .825 0 1.084.062.23.055.45.146.65.27.228.139.422.333.811.722l2.392 2.392c.389.389.583.583.722.81.124.202.215.421.27.65.062.26.062.535.062 1.085Z"
    />
  </svg>
);
