import { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import {
  Input, ModalWindow, ActionButton, Button, Loader,
} from 'components';
import parseDecimalNumber from 'helpers/parseDecimalNumber';
import { useStateVar } from 'helpers/useStateVar';
import { DeleteOutlineIcon, EditIcon } from 'icons';
import { apiCall } from 'providers';
import { colors } from 'styles/colors';

import { AdminLayout } from '../AdminLayout';
import {
  TableContainer,
  TableHead,
  TableBody,
  HeaderCell,
  DataCell,
  Row,
  HeaderRow,
  SimpleButton,
} from './styles';

import { withTransaction } from '@elastic/apm-rum-react';
import { formatNumberWithFractionDigits } from '~/helpers/thousandFormatNumber';

export const SensorsConfig = (): JSX.Element => {
  const [state, render, setState] = useStateVar(() => {
    const state = {
      sensorsList: [] as { SENSOR_ID: string; MULT_QUAD: number; MULT_LIN: number; OFST: number; SENSOR_NAME: string; }[],
      openModal: false,
      selectedSensor: null,
      loading: true,
    };
    return state;
  });

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setState({ loading: true });
      const sensorsList = (await apiCall('/config/get-pressure-sensors', {})).list || [];
      state.sensorsList = sensorsList.filter((item) => item.SENSOR_ID !== 'NO_CONV');
    } catch (err) { console.log(err); toast.error('Houve erro'); }
    setState({ loading: false });
  }
  function openCreateEditSensor(itemToEdit?) {
    state.selectedSensor = itemToEdit || null;
    setState({ openModal: true });
  }
  async function deleteSensor(item) {
    try {
      if (window.confirm(`Deseja excluir o sensor ${item.SENSOR_ID}?`)) {
        await apiCall('/config/delete-pressure-sensor', { SENSOR_ID: item.SENSOR_ID });
        state.sensorsList = state.sensorsList.filter((sensor) => sensor.SENSOR_ID !== item.SENSOR_ID);
        render();
        toast.success('Sucesso');
      }
    } catch (err) { console.log(err); toast.error('Houve erro'); }
  }
  async function afterCreateEditSensor({ item: responseData, action }) {
    try {
      setState({ openModal: false });
      if (action === 'new') {
        state.sensorsList.push(responseData);
        render();
      }
      else if (action === 'edit') {
        const found = state.sensorsList.find((item) => item.SENSOR_ID === responseData.SENSOR_ID);
        if (found) {
          Object.assign(found, responseData);
          render();
        }
        else {
          await fetchData();
        }
      }
      else {
        await fetchData();
      }
      toast.success('Sucesso');
    } catch (err) { console.log(err); toast.error('Houve erro'); }
  }

  return (
    <>
      <Helmet>
        <title>Diel Energia - Configurações</title>
      </Helmet>
      <AdminLayout />
      {state.loading && <Loader variant="primary" />}
      {(!state.loading)
        && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>&nbsp;</div>
            <SimpleButton variant="primary" onClick={() => openCreateEditSensor()}>Adicionar Sensor</SimpleButton>
          </div>
          <TableSensors
            list={state.sensorsList}
            onDeleteClick={deleteSensor}
            onEditClick={openCreateEditSensor}
          />
          {state.openModal && (
            <ModalWindow onClickOutside={undefined}>
              <FormEditSensor
                sensorInfo={state.selectedSensor}
                onCancel={() => { setState({ openModal: false }); }}
                onSuccess={afterCreateEditSensor}
              />
            </ModalWindow>
          )}
        </div>
        )}
    </>
  );
};

function TableSensors({ list, onDeleteClick, onEditClick }) {
  return (
    <TableContainer>
      <TableHead>
        <HeaderRow>
          <HeaderCell>ID</HeaderCell>
          <HeaderCell>Fator A</HeaderCell>
          <HeaderCell>Offset B</HeaderCell>
          <HeaderCell>Fator C</HeaderCell>
          <HeaderCell />
        </HeaderRow>
      </TableHead>
      <TableBody>
        {list.map((item) => (
          <Row key={item.SENSOR_ID}>
            <DataCell>{item.SENSOR_ID}</DataCell>
            <DataCell>{formatNumberWithFractionDigits(item.MULT_LIN, { minimum: 0, maximum: 6 })}</DataCell>
            <DataCell>{formatNumberWithFractionDigits(item.OFST, { minimum: 0, maximum: 6 })}</DataCell>
            <DataCell>{formatNumberWithFractionDigits(item.MULT_QUAD, { minimum: 0, maximum: 6 })}</DataCell>
            <DataCell>
              <ActionButton onClick={() => onDeleteClick(item)} variant="red-inv">
                <DeleteOutlineIcon colors={colors.Red} />
              </ActionButton>
              <ActionButton onClick={() => onEditClick(item)} variant="blue-inv">
                <EditIcon color={colors.LightBlue} />
              </ActionButton>
            </DataCell>
          </Row>
        ))}
      </TableBody>
    </TableContainer>
  );
}

function FormEditSensor({ sensorInfo, onSuccess, onCancel }) {
  const [state, render, setState] = useStateVar({
    submitting: false,
    idTouched: false,
    SENSOR_ID: (sensorInfo && sensorInfo.SENSOR_ID) || '',
    SENSOR_NAME: (sensorInfo && sensorInfo.SENSOR_NAME) || '',
    MULT_QUAD: (sensorInfo && String(sensorInfo.MULT_QUAD)) || '0',
    MULT_LIN: (sensorInfo && String(sensorInfo.MULT_LIN)) || '1',
    OFST: (sensorInfo && String(sensorInfo.OFST)) || '0',
  });
  const isEdit = !!(sensorInfo && sensorInfo.SENSOR_ID);
  if (isEdit) { state.SENSOR_ID = sensorInfo.SENSOR_ID; }

  async function confirm() {
    let response = null as null|{ SENSOR_ID: string };
    let action = null as null|'new'|'edit';
    try {
      if (!state.MULT_QUAD) { toast.error('É necessário informar o fator C'); return; }
      if (!state.MULT_LIN) { toast.error('É necessário informar o fator A'); return; }
      if (!state.OFST) { toast.error('É necessário informar o offset'); return; }
      setState({ submitting: true });
      const reqData = {
        SENSOR_ID: state.SENSOR_ID || null,
        SENSOR_NAME: state.SENSOR_NAME || null,
        MULT_QUAD: parseDecimalNumber(state.MULT_QUAD)!,
        MULT_LIN: parseDecimalNumber(state.MULT_LIN)!,
        OFST: parseDecimalNumber(state.OFST)!,
      };
      if (isEdit) {
        response = await apiCall('/config/edit-pressure-sensor', reqData);
        action = 'edit';
      } else {
        response = await apiCall('/config/add-pressure-sensor', reqData);
        action = 'new';
      }
    } catch (err) { console.log(err); toast.error('Houve erro'); }
    setState({ submitting: false });
    if (response && action) onSuccess({ item: response, action });
  }

  return (
    <div>
      {/* <Input
        type='text'
        value={state.SENSOR_NAME}
        placeholder='Nome'
        onChange={event => { state.SENSOR_NAME = event.target.value; render() }}
      />
      <div style={{ paddingTop: '10px'}}></div> */}
      <Input
        type="text"
        value={state.SENSOR_ID}
        placeholder="ID"
        onChange={(event) => setState({ SENSOR_ID: event.target.value, idTouched: true })}
        disabled={isEdit}
      />
      <div style={{ paddingTop: '10px' }} />
      <Input
        type="text"
        value={state.MULT_LIN}
        placeholder="Fator A"
        onChange={(event) => setState({ MULT_LIN: event.target.value })}
      />
      <div style={{ paddingTop: '10px' }} />
      <Input
        type="text"
        value={state.OFST}
        placeholder="Offset B"
        onChange={(event) => setState({ OFST: event.target.value })}
      />
      <div style={{ paddingTop: '10px' }} />
      <Input
        type="text"
        value={state.MULT_QUAD}
        placeholder="Fator C"
        onChange={(event) => setState({ MULT_QUAD: event.target.value })}
      />
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px',
      }}
      >
        <Button style={{ width: '140px' }} onClick={confirm} variant="primary">
          {isEdit ? 'Salvar' : 'Adicionar'}
        </Button>
        <Button style={{ width: '140px', margin: '0 20px' }} onClick={onCancel} variant="grey">
          Cancelar
        </Button>
      </div>
    </div>
  );
}

export default withTransaction('SensorsConfig', 'component')(SensorsConfig);
