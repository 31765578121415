type ExportIconProps = {
  color?: string;
  style?: {};
  heigth?: string;
  width?: string;
}

export const ExportIcon = ({
  color = '#363BC4', width = '19', heigth = '19', ...props
}: ExportIconProps): JSX.Element => (
  <svg {...props} width={width} height={heigth} viewBox="0 3 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2382 18.1498H13.2383C14.0797 18.149 14.8864 17.8145 15.4814 17.2195C16.0763 16.6245 16.4109 15.8178 16.4117 14.9764V14.9763V9.79308V9.79294C16.4109 8.95154 16.0763 8.14484 15.4814 7.54988C14.8864 6.95493 14.0797 6.62033 13.2383 6.61953H13.2382L11.5104 6.61953C11.2797 6.61953 11.0585 6.71118 10.8953 6.87432C10.7322 7.03745 10.6405 7.25871 10.6405 7.48942C10.6405 7.72013 10.7322 7.94139 10.8953 8.10453C11.0585 8.26767 11.2797 8.35932 11.5104 8.35932H13.2381C13.6181 8.36006 13.9824 8.51136 14.2512 8.7801C14.5199 9.04888 14.6712 9.41323 14.6719 9.79335L14.6719 14.976C14.6712 15.3561 14.5199 15.7205 14.2512 15.9893C13.9824 16.2581 13.618 16.4094 13.2379 16.4101H4.02382C3.6437 16.4094 3.27935 16.2581 3.01056 15.9893C2.74181 15.7205 2.59051 15.3562 2.58978 14.9762L2.58978 9.79322C2.59051 9.41315 2.74181 9.04885 3.01056 8.7801C3.2793 8.51136 3.64358 8.36006 4.02363 8.35932H5.75129C5.982 8.35932 6.20326 8.26767 6.3664 8.10453C6.52953 7.94139 6.62118 7.72013 6.62118 7.48942C6.62118 7.25871 6.52953 7.03745 6.3664 6.87432C6.20326 6.71118 5.982 6.61953 5.75129 6.61953L4.02355 6.61953L4.0234 6.61953C3.18201 6.62033 2.37531 6.95493 1.78035 7.54988C1.1854 8.14484 0.8508 8.95154 0.85 9.79294V9.79308L0.85 14.9763L0.85 14.9764C0.8508 15.8178 1.1854 16.6245 1.78035 17.2195C2.37531 17.8145 3.18201 18.149 4.0234 18.1498H4.02355H13.2382Z" fill={color} stroke={color} strokeWidth="0.3" />
    <path d="M9.24482 1.10571C9.08169 0.942572 8.86043 0.850923 8.62972 0.850923C8.39901 0.850923 8.17775 0.942572 8.01461 1.10571C7.85148 1.26884 7.75983 1.49011 7.75983 1.72081L7.75982 13.2391C7.75982 13.4698 7.85147 13.6911 8.01461 13.8542C8.17775 14.0173 8.39901 14.109 8.62972 14.109C8.86043 14.109 9.08169 14.0173 9.24482 13.8542C9.40796 13.6911 9.49961 13.4698 9.49961 13.2391L9.49961 1.72081C9.49961 1.49011 9.40796 1.26884 9.24482 1.10571Z" fill={color} stroke={color} strokeWidth="0.3" />
    <path d="M5.1323 9.75619C4.97114 9.91997 4.8808 10.1406 4.8808 10.3704C4.8808 10.6006 4.97146 10.8215 5.13316 10.9854L5.13386 10.9861L8.01343 13.8657L8.01343 13.8657L8.01414 13.8664C8.17801 14.0281 8.39897 14.1187 8.62918 14.1187C8.8594 14.1187 9.08036 14.0281 9.24422 13.8664L9.24493 13.8657L12.1245 10.9861C12.2878 10.8228 12.3796 10.6013 12.3796 10.3704C12.3796 10.1394 12.2878 9.91792 12.1245 9.75462C11.9612 9.59131 11.7397 9.49956 11.5088 9.49956C11.2778 9.49956 11.0563 9.59131 10.893 9.75462L8.62944 12.0182L6.36562 9.74335L6.36541 9.74314C6.28453 9.6622 6.18844 9.59807 6.08266 9.55444C5.97688 9.51082 5.86352 9.48856 5.7491 9.48895L5.74777 9.48897C5.63236 9.49039 5.51841 9.51483 5.41258 9.56087C5.30712 9.60675 5.21185 9.67315 5.1323 9.75619ZM5.1323 9.75619C5.13259 9.7559 5.13287 9.75561 5.13316 9.75532L5.23993 9.86068L5.13146 9.75708C5.13174 9.75678 5.13202 9.75649 5.1323 9.75619Z" fill={color} stroke={color} strokeWidth="0.3" />
  </svg>
);
