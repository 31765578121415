type CalendarIconProps = {
  color?: string;
}

export const CalendarIcon = ({ color = '#363BC4', ...props }: CalendarIconProps): JSX.Element => (
  <svg
    className="date-picker-no-close"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.95461 3.228C9.78535 3.228 9.62302 3.15715 9.50333 3.03103C9.38365 2.90491 9.31641 2.73386 9.31641 2.5555V0.6725C9.31641 0.494142 9.38365 0.323089 9.50333 0.196971C9.62302 0.0708525 9.78535 0 9.95461 0C10.1239 0 10.2862 0.0708525 10.4059 0.196971C10.5256 0.323089 10.5928 0.494142 10.5928 0.6725V2.5555C10.5928 2.64381 10.5763 2.73126 10.5442 2.81286C10.5122 2.89445 10.4652 2.96858 10.4059 3.03103C10.3466 3.09348 10.2763 3.14301 10.1988 3.17681C10.1214 3.21061 10.0384 3.228 9.95461 3.228Z"
      fill={color}
    />
    <path
      d="M3.57571 3.228C3.40644 3.228 3.24411 3.15715 3.12443 3.03103C3.00474 2.90491 2.9375 2.73386 2.9375 2.5555V0.6725C2.9375 0.494142 3.00474 0.323089 3.12443 0.196971C3.24411 0.0708525 3.40644 0 3.57571 0C3.74497 0 3.9073 0.0708525 4.02699 0.196971C4.14668 0.323089 4.21392 0.494142 4.21392 0.6725V2.5555C4.21392 2.64381 4.19741 2.73126 4.16533 2.81286C4.13326 2.89445 4.08625 2.96858 4.02699 3.03103C3.96773 3.09348 3.89737 3.14301 3.81994 3.17681C3.74251 3.21061 3.65952 3.228 3.57571 3.228Z"
      fill={color}
    />
    <path
      d="M10.8495 15.8708H2.68047C1.96977 15.8701 1.28838 15.5723 0.785838 15.0428C0.283299 14.5132 0.00067589 13.7952 0 13.0463L0 4.70731C0.00067589 3.95843 0.283299 3.24042 0.785838 2.71088C1.28838 2.18133 1.96977 1.88352 2.68047 1.88281H10.8495C11.5602 1.88352 12.2416 2.18133 12.7442 2.71088C13.2467 3.24042 13.5293 3.95843 13.53 4.70731V13.0463C13.5293 13.7952 13.2467 14.5132 12.7442 15.0428C12.2416 15.5723 11.5602 15.8701 10.8495 15.8708ZM2.68047 3.22781C2.3083 3.22852 1.95156 3.38463 1.6884 3.66193C1.42523 3.93924 1.27709 4.31514 1.27642 4.70731V13.0463C1.27709 13.4385 1.42523 13.8144 1.6884 14.0917C1.95156 14.369 2.3083 14.5251 2.68047 14.5258H10.8495C11.2217 14.5251 11.5784 14.369 11.8416 14.0917C12.1048 13.8144 12.2529 13.4385 12.2536 13.0463V4.70731C12.2529 4.31514 12.1048 3.93924 11.8416 3.66193C11.5784 3.38463 11.2217 3.22852 10.8495 3.22781H2.68047Z"
      fill={color}
    />
    <path
      d="M12.8918 6.72586H0.638208C0.468944 6.72586 0.306614 6.65501 0.186927 6.52889C0.0672395 6.40277 0 6.23172 0 6.05336C0 5.875 0.0672395 5.70395 0.186927 5.57783C0.306614 5.45171 0.468944 5.38086 0.638208 5.38086H12.8918C13.0611 5.38086 13.2234 5.45171 13.3431 5.57783C13.4628 5.70395 13.53 5.875 13.53 6.05336C13.53 6.23172 13.4628 6.40277 13.3431 6.52889C13.2234 6.65501 13.0611 6.72586 12.8918 6.72586Z"
      fill={color}
    />
    <path
      d="M8.80672 11.3225H4.72219C4.55293 11.3225 4.3906 11.2517 4.27091 11.1256C4.15122 10.9995 4.08398 10.8284 4.08398 10.65C4.08398 10.4717 4.15122 10.3006 4.27091 10.1745C4.3906 10.0484 4.55293 9.97754 4.72219 9.97754H8.80672C8.97598 9.97754 9.13831 10.0484 9.258 10.1745C9.37769 10.3006 9.44493 10.4717 9.44493 10.65C9.44493 10.8284 9.37769 10.9995 9.258 11.1256C9.13831 11.2517 8.97598 11.3225 8.80672 11.3225Z"
      fill={color}
    />
    <path
      d="M6.76321 13.4742C6.59394 13.4742 6.43161 13.4033 6.31193 13.2772C6.19224 13.1511 6.125 12.9801 6.125 12.8017V8.4977C6.125 8.31934 6.19224 8.14828 6.31193 8.02217C6.43161 7.89605 6.59394 7.8252 6.76321 7.8252C6.93247 7.8252 7.0948 7.89605 7.21449 8.02217C7.33418 8.14828 7.40142 8.31934 7.40142 8.4977V12.8017C7.40142 12.9801 7.33418 13.1511 7.21449 13.2772C7.0948 13.4033 6.93247 13.4742 6.76321 13.4742Z"
      fill={color}
    />
  </svg>
);
