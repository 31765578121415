type WifiMiniOffIconProps = {
  color?: string;
  size?: number;
}

export const WifiMiniOffIcon = ({ color = '#C6C6C6', size }: WifiMiniOffIconProps): JSX.Element => (
  <svg width={size || '416'} height={size || '324'} viewBox="0 0 416 324" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M338.482 189.31C338.162 213.68 326.052 227.6 306.002 229.55C295.782 230.55 287.102 226.47 279.792 219.55C269.562 209.9 258.682 201.24 245.742 195.46C225.742 186.53 205.262 185.97 184.342 191.46C167.132 196 153.342 206.37 139.862 217.3C131.712 223.91 122.862 228.94 112.012 229.21C98.7324 229.53 88.8924 223.4 82.2724 212.42C75.4524 201.11 75.4924 189.09 82.2724 177.85C90.1824 164.85 101.942 155.36 114.272 146.72C137.272 130.58 162.832 121.91 190.792 119.46C221.202 116.8 250.492 120.28 278.352 133.26C296.967 141.925 313.679 154.195 327.522 169.36C333.442 175.88 337.872 183.58 338.482 189.31Z" fill={color} />
    <path d="M207.882 323.59C184.672 323.46 166.502 304.94 166.462 281.35C166.422 257.76 184.762 239.14 207.852 239.15C231.192 239.15 249.352 257.91 249.202 281.79C249.042 304.88 230.442 323.71 207.882 323.59Z" fill={color} />
    <path d="M406.042 85.48C382.636 59.5495 353.765 39.1385 321.512 25.72C294.863 14.4299 266.809 6.79738 238.112 3.02998C228.112 1.73998 217.942 1.47999 207.832 0.899994C197.722 1.47999 187.573 1.73998 177.553 3.02998C148.846 6.79377 120.781 14.4264 94.1224 25.72C61.8667 39.1372 32.9919 59.5483 9.58241 85.48C-8.1776 105.11 -0.22756 133.34 24.9924 141.41C40.2724 146.3 52.2724 140.29 63.1024 130.29C77.9524 116.56 93.9324 104.42 111.812 94.84C141.37 79.1206 174.335 70.8998 207.812 70.8998C241.29 70.8998 274.255 79.1206 303.812 94.84C321.692 104.42 337.672 116.56 352.522 130.29C363.352 140.29 375.352 146.29 390.632 141.41C415.862 133.34 423.802 105.11 406.042 85.48Z" fill={color} />
  </svg>
);
