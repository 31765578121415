export function MonitoramentoIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2887 14.0475H3.71966C3.2524 14.0467 2.80449 13.8608 2.47408 13.5304C2.14368 13.2 1.95775 12.7521 1.95703 12.2848V3.71575C1.95775 3.24849 2.14368 2.80058 2.47408 2.47018C2.80449 2.13978 3.2524 1.95384 3.71966 1.95312H12.2887C12.756 1.95384 13.2039 2.13978 13.5343 2.47018C13.8647 2.80058 14.0506 3.24849 14.0514 3.71575V12.2848C14.0506 12.7521 13.8647 13.2 13.5343 13.5304C13.2039 13.8608 12.756 14.0467 12.2887 14.0475ZM3.71966 3.30899C3.61178 3.30899 3.50832 3.35185 3.43203 3.42813C3.35575 3.50441 3.3129 3.60787 3.3129 3.71575V12.2848C3.3129 12.3927 3.35575 12.4962 3.43203 12.5724C3.50832 12.6487 3.61178 12.6916 3.71966 12.6916H12.2887C12.3966 12.6916 12.5001 12.6487 12.5764 12.5724C12.6526 12.4962 12.6955 12.3927 12.6955 12.2848V3.71575C12.6955 3.60787 12.6526 3.50441 12.5764 3.42813C12.5001 3.35185 12.3966 3.30899 12.2887 3.30899H3.71966Z" fill="#363BC4" />
      <path d="M10.7069 11.3897H5.2834C5.1036 11.3897 4.93117 11.3183 4.80403 11.1911C4.67689 11.064 4.60547 10.8915 4.60547 10.7117V5.28828C4.60547 5.10849 4.67689 4.93605 4.80403 4.80891C4.93117 4.68178 5.1036 4.61035 5.2834 4.61035H10.7069C10.8867 4.61035 11.0591 4.68178 11.1862 4.80891C11.3134 4.93605 11.3848 5.10849 11.3848 5.28828V10.7117C11.3848 10.8915 11.3134 11.064 11.1862 11.1911C11.0591 11.3183 10.8867 11.3897 10.7069 11.3897ZM5.96133 10.0338H10.0289V5.96622H5.96133V10.0338Z" fill="#363BC4" />
      <path d="M3.8459 3.30831C3.6661 3.30831 3.49367 3.23689 3.36653 3.10975C3.23939 2.98261 3.16797 2.81018 3.16797 2.63038V0.677933C3.16797 0.498134 3.23939 0.325699 3.36653 0.198562C3.49367 0.0714249 3.6661 0 3.8459 0C4.0257 0 4.19814 0.0714249 4.32527 0.198562C4.45241 0.325699 4.52383 0.498134 4.52383 0.677933V2.63038C4.52383 2.81018 4.45241 2.98261 4.32527 3.10975C4.19814 3.23689 4.0257 3.30831 3.8459 3.30831Z" fill="#363BC4" />
      <path d="M6.61543 3.30831C6.43563 3.30831 6.2632 3.23689 6.13606 3.10975C6.00892 2.98261 5.9375 2.81018 5.9375 2.63038V0.677933C5.9375 0.498134 6.00892 0.325699 6.13606 0.198562C6.2632 0.0714249 6.43563 0 6.61543 0C6.79523 0 6.96767 0.0714249 7.0948 0.198562C7.22194 0.325699 7.29337 0.498134 7.29337 0.677933V2.63038C7.29337 2.81018 7.22194 2.98261 7.0948 3.10975C6.96767 3.23689 6.79523 3.30831 6.61543 3.30831Z" fill="#363BC4" />
      <path d="M9.38496 3.30831C9.20517 3.30831 9.03273 3.23689 8.90559 3.10975C8.77846 2.98261 8.70703 2.81018 8.70703 2.63038V0.677933C8.70703 0.498134 8.77846 0.325699 8.90559 0.198562C9.03273 0.0714249 9.20517 0 9.38496 0C9.56476 0 9.7372 0.0714249 9.86434 0.198562C9.99147 0.325699 10.0629 0.498134 10.0629 0.677933V2.63038C10.0629 2.81018 9.99147 2.98261 9.86434 3.10975C9.7372 3.23689 9.56476 3.30831 9.38496 3.30831Z" fill="#363BC4" />
      <path d="M12.1467 3.30831C11.9669 3.30831 11.7944 3.23689 11.6673 3.10975C11.5402 2.98261 11.4688 2.81018 11.4688 2.63038V0.677933C11.4688 0.498134 11.5402 0.325699 11.6673 0.198562C11.7944 0.0714249 11.9669 0 12.1467 0C12.3265 0 12.4989 0.0714249 12.6261 0.198562C12.7532 0.325699 12.8246 0.498134 12.8246 0.677933V2.63038C12.8246 2.81018 12.7532 2.98261 12.6261 3.10975C12.4989 3.23689 12.3265 3.30831 12.1467 3.30831Z" fill="#363BC4" />
      <path d="M3.8459 16.0002C3.6661 16.0002 3.49367 15.9288 3.36653 15.8016C3.23939 15.6745 3.16797 15.5021 3.16797 15.3223V13.3698C3.16797 13.19 3.23939 13.0176 3.36653 12.8905C3.49367 12.7633 3.6661 12.6919 3.8459 12.6919C4.0257 12.6919 4.19814 12.7633 4.32527 12.8905C4.45241 13.0176 4.52383 13.19 4.52383 13.3698V15.3223C4.52383 15.5021 4.45241 15.6745 4.32527 15.8016C4.19814 15.9288 4.0257 16.0002 3.8459 16.0002Z" fill="#363BC4" />
      <path d="M6.61543 16.0002C6.43563 16.0002 6.2632 15.9288 6.13606 15.8016C6.00892 15.6745 5.9375 15.5021 5.9375 15.3223V13.3698C5.9375 13.19 6.00892 13.0176 6.13606 12.8905C6.2632 12.7633 6.43563 12.6919 6.61543 12.6919C6.79523 12.6919 6.96767 12.7633 7.0948 12.8905C7.22194 13.0176 7.29337 13.19 7.29337 13.3698V15.3223C7.29337 15.5021 7.22194 15.6745 7.0948 15.8016C6.96767 15.9288 6.79523 16.0002 6.61543 16.0002Z" fill="#363BC4" />
      <path d="M9.38496 16.0002C9.20517 16.0002 9.03273 15.9288 8.90559 15.8016C8.77846 15.6745 8.70703 15.5021 8.70703 15.3223V13.3698C8.70703 13.19 8.77846 13.0176 8.90559 12.8905C9.03273 12.7633 9.20517 12.6919 9.38496 12.6919C9.56476 12.6919 9.7372 12.7633 9.86434 12.8905C9.99147 13.0176 10.0629 13.19 10.0629 13.3698V15.3223C10.0629 15.5021 9.99147 15.6745 9.86434 15.8016C9.7372 15.9288 9.56476 16.0002 9.38496 16.0002Z" fill="#363BC4" />
      <path d="M12.1467 16.0002C11.9669 16.0002 11.7944 15.9288 11.6673 15.8016C11.5402 15.6745 11.4688 15.5021 11.4688 15.3223V13.3698C11.4688 13.19 11.5402 13.0176 11.6673 12.8905C11.7944 12.7633 11.9669 12.6919 12.1467 12.6919C12.3265 12.6919 12.4989 12.7633 12.6261 12.8905C12.7532 13.0176 12.8246 13.19 12.8246 13.3698V15.3223C12.8246 15.5021 12.7532 15.6745 12.6261 15.8016C12.4989 15.9288 12.3265 16.0002 12.1467 16.0002Z" fill="#363BC4" />
      <path d="M15.3218 4.52872H13.3693C13.1895 4.52872 13.0171 4.45729 12.89 4.33016C12.7628 4.20302 12.6914 4.03058 12.6914 3.85078C12.6914 3.67099 12.7628 3.49855 12.89 3.37141C13.0171 3.24428 13.1895 3.17285 13.3693 3.17285H15.3218C15.5016 3.17285 15.674 3.24428 15.8012 3.37141C15.9283 3.49855 15.9997 3.67099 15.9997 3.85078C15.9997 4.03058 15.9283 4.20302 15.8012 4.33016C15.674 4.45729 15.5016 4.52872 15.3218 4.52872Z" fill="#363BC4" />
      <path d="M15.3218 7.29434H13.3693C13.1895 7.29434 13.0171 7.22292 12.89 7.09578C12.7628 6.96864 12.6914 6.79621 12.6914 6.61641C12.6914 6.43661 12.7628 6.26418 12.89 6.13704C13.0171 6.0099 13.1895 5.93848 13.3693 5.93848H15.3218C15.5016 5.93848 15.674 6.0099 15.8012 6.13704C15.9283 6.26418 15.9997 6.43661 15.9997 6.61641C15.9997 6.79621 15.9283 6.96864 15.8012 7.09578C15.674 7.22292 15.5016 7.29434 15.3218 7.29434Z" fill="#363BC4" />
      <path d="M15.3218 10.0609H13.3693C13.1895 10.0609 13.0171 9.98952 12.89 9.86238C12.7628 9.73525 12.6914 9.56281 12.6914 9.38301C12.6914 9.20321 12.7628 9.03078 12.89 8.90364C13.0171 8.7765 13.1895 8.70508 13.3693 8.70508H15.3218C15.5016 8.70508 15.674 8.7765 15.8012 8.90364C15.9283 9.03078 15.9997 9.20321 15.9997 9.38301C15.9997 9.56281 15.9283 9.73525 15.8012 9.86238C15.674 9.98952 15.5016 10.0609 15.3218 10.0609Z" fill="#363BC4" />
      <path d="M15.3218 12.8266H13.3693C13.1895 12.8266 13.0171 12.7551 12.89 12.628C12.7628 12.5009 12.6914 12.3284 12.6914 12.1486C12.6914 11.9688 12.7628 11.7964 12.89 11.6693C13.0171 11.5421 13.1895 11.4707 13.3693 11.4707H15.3218C15.5016 11.4707 15.674 11.5421 15.8012 11.6693C15.9283 11.7964 15.9997 11.9688 15.9997 12.1486C15.9997 12.3284 15.9283 12.5009 15.8012 12.628C15.674 12.7551 15.5016 12.8266 15.3218 12.8266Z" fill="#363BC4" />
      <path d="M2.63038 4.52872H0.677933C0.498134 4.52872 0.325699 4.45729 0.198562 4.33016C0.0714249 4.20302 0 4.03058 0 3.85078C0 3.67099 0.0714249 3.49855 0.198562 3.37141C0.325699 3.24428 0.498134 3.17285 0.677933 3.17285H2.63038C2.81018 3.17285 2.98261 3.24428 3.10975 3.37141C3.23689 3.49855 3.30831 3.67099 3.30831 3.85078C3.30831 4.03058 3.23689 4.20302 3.10975 4.33016C2.98261 4.45729 2.81018 4.52872 2.63038 4.52872Z" fill="#363BC4" />
      <path d="M2.63038 7.29434H0.677933C0.498134 7.29434 0.325699 7.22292 0.198562 7.09578C0.0714249 6.96864 0 6.79621 0 6.61641C0 6.43661 0.0714249 6.26418 0.198562 6.13704C0.325699 6.0099 0.498134 5.93848 0.677933 5.93848H2.63038C2.81018 5.93848 2.98261 6.0099 3.10975 6.13704C3.23689 6.26418 3.30831 6.43661 3.30831 6.61641C3.30831 6.79621 3.23689 6.96864 3.10975 7.09578C2.98261 7.22292 2.81018 7.29434 2.63038 7.29434Z" fill="#363BC4" />
      <path d="M2.63038 10.0609H0.677933C0.498134 10.0609 0.325699 9.98952 0.198562 9.86238C0.0714249 9.73525 0 9.56281 0 9.38301C0 9.20321 0.0714249 9.03078 0.198562 8.90364C0.325699 8.7765 0.498134 8.70508 0.677933 8.70508H2.63038C2.81018 8.70508 2.98261 8.7765 3.10975 8.90364C3.23689 9.03078 3.30831 9.20321 3.30831 9.38301C3.30831 9.56281 3.23689 9.73525 3.10975 9.86238C2.98261 9.98952 2.81018 10.0609 2.63038 10.0609Z" fill="#363BC4" />
      <path d="M2.63038 12.8261H0.677933C0.498134 12.8261 0.325699 12.7547 0.198562 12.6275C0.0714249 12.5004 0 12.3279 0 12.1481C0 11.9683 0.0714249 11.7959 0.198562 11.6688C0.325699 11.5416 0.498134 11.4702 0.677933 11.4702H2.63038C2.81018 11.4702 2.98261 11.5416 3.10975 11.6688C3.23689 11.7959 3.30831 11.9683 3.30831 12.1481C3.30831 12.3279 3.23689 12.5004 3.10975 12.6275C2.98261 12.7547 2.81018 12.8261 2.63038 12.8261Z" fill="#363BC4" />
    </svg>
  );
}
