type DeleteNotificationIconProps = {
  width?: string;
  height?: string;
}

export const DeleteNotificationIcon = ({ width, height }: DeleteNotificationIconProps): JSX.Element => (
  <svg width={width ?? '12'} height={height ?? '13'} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.61741 12.8926H4.596C4.10181 12.8905 3.62613 12.698 3.26207 12.3527C2.89801 12.0073 2.67178 11.534 2.62766 11.0254L2.03125 3.64213L3.01542 3.5567L3.61577 10.94C3.6356 11.1953 3.74798 11.4336 3.93045 11.607C4.11291 11.7805 4.35201 11.8764 4.59994 11.8756H7.61741C7.86456 11.8736 8.10217 11.7768 8.28406 11.6038C8.46595 11.4309 8.57912 11.1943 8.60159 10.94L9.19209 3.5567L10.1763 3.64213L9.57592 11.0254C9.53196 11.5323 9.30713 12.0042 8.94515 12.3493C8.58316 12.6944 8.10991 12.888 7.61741 12.8926Z" fill="#ED193F" />
    <path d="M11.1055 4.10865H1.11025C0.979741 4.10865 0.854577 4.05508 0.762293 3.95972C0.670009 3.86436 0.618164 3.73502 0.618164 3.60016C0.618164 3.4653 0.670009 3.33597 0.762293 3.24061C0.854577 3.14525 0.979741 3.09167 1.11025 3.09167H11.1055C11.236 3.09167 11.3612 3.14525 11.4535 3.24061C11.5457 3.33597 11.5976 3.4653 11.5976 3.60016C11.5976 3.73502 11.5457 3.86436 11.4535 3.95972C11.3612 4.05508 11.236 4.10865 11.1055 4.10865Z" fill="#ED193F" />
    <path d="M8.72557 4.10826H7.7414V3.59977C7.74088 3.15167 7.56838 2.72206 7.26174 2.4052C6.9551 2.08834 6.53936 1.91009 6.10571 1.90956C5.67364 1.91535 5.26084 2.09529 4.9553 2.41102C4.64975 2.72675 4.47562 3.1533 4.47001 3.59977V4.10826H3.48584V3.59977C3.48584 2.88178 3.76186 2.1932 4.25318 1.6855C4.7445 1.1778 5.41087 0.892578 6.10571 0.892578C6.80054 0.892578 7.46691 1.1778 7.95823 1.6855C8.44955 2.1932 8.72557 2.88178 8.72557 3.59977V4.10826Z" fill="#ED193F" />
    <path d="M7.02734 10.1251H6.99388C6.92939 10.1207 6.86636 10.1032 6.8084 10.0737C6.75045 10.0441 6.6987 10.003 6.65611 9.95278C6.61352 9.90254 6.58092 9.84412 6.56019 9.78086C6.53945 9.7176 6.53098 9.65074 6.53526 9.58409L6.73209 6.65316C6.74071 6.5183 6.80081 6.3925 6.89919 6.30343C6.99756 6.21437 7.12615 6.16933 7.25666 6.17823C7.38717 6.18713 7.50891 6.24924 7.5951 6.35089C7.68129 6.45255 7.72488 6.58542 7.71627 6.72028L7.51943 9.65121C7.51048 9.77982 7.45477 9.90016 7.36349 9.98807C7.27221 10.076 7.15212 10.1249 7.02734 10.1251Z" fill="#ED193F" />
    <path d="M5.18851 10.1248C5.06362 10.1245 4.94349 10.0752 4.85248 9.98683C4.76147 9.89845 4.70639 9.77762 4.69839 9.64883L4.50156 6.7179C4.49295 6.58304 4.53653 6.45017 4.62272 6.34851C4.70892 6.24686 4.83066 6.18475 4.96117 6.17585C5.09168 6.16695 5.22026 6.21199 5.31864 6.30105C5.41701 6.39012 5.47712 6.51592 5.48573 6.65078L5.68257 9.58171C5.68684 9.64836 5.67837 9.71522 5.65764 9.77848C5.6369 9.84174 5.60431 9.90016 5.56172 9.9504C5.51913 10.0006 5.46738 10.0417 5.40942 10.0713C5.35147 10.1009 5.28844 10.1183 5.22394 10.1227L5.18851 10.1248Z" fill="#ED193F" />
  </svg>

);
