type PinIconProps = {
  color?: string;
}

export const PinIcon = ({ color = '#3F3F3F', ...props }: PinIconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    {...props}
  >
    <path
      d="M7.19812 0C11.4599 0.0217879 14.6465 2.85117 15.1436 6.67773C15.2981 7.87302 15.0534 8.99332 14.606 10.0868C13.5926 12.5569 12.1232 14.7585 10.5763 16.913C9.90286 17.8519 9.19146 18.7644 8.48158 19.6765C8.02555 20.2622 7.19863 20.2845 6.74868 19.718C4.70113 17.1339 2.7615 14.4798 1.24902 11.539C0.719524 10.5088 0.242216 9.45188 0.0694332 8.29358C-0.133245 6.93412 0.115544 5.62583 0.714458 4.40571C2.11293 1.55656 4.45234 0.140861 7.19812 0ZM12.5863 7.60549C12.5954 4.86123 10.366 2.61606 7.62324 2.60796C6.96514 2.60515 6.31296 2.73232 5.70412 2.98217C5.09528 3.23201 4.54176 3.59961 4.07533 4.06388C3.6089 4.52815 3.23872 5.07995 2.98604 5.68762C2.73337 6.29529 2.60317 6.94687 2.60291 7.60498C2.59429 10.3214 4.84199 12.5817 7.5599 12.5934C8.21788 12.5975 8.87018 12.4715 9.47933 12.2228C10.0885 11.974 10.6425 11.6074 11.1096 11.1439C11.5766 10.6804 11.9475 10.1292 12.2009 9.52201C12.4544 8.91478 12.5853 8.26347 12.5863 7.60549Z"
      fill={color}
    />
    <path
      d="M7.58785 10.7908C5.77237 10.769 4.39162 9.34875 4.40885 7.51958C4.42456 5.79124 5.89093 4.37807 7.64562 4.40391C8.49157 4.41752 9.29769 4.76567 9.88758 5.37218C10.4775 5.97869 10.8031 6.79417 10.7932 7.64017C10.7719 9.38776 9.32379 10.8116 7.58785 10.7908Z"
      fill={color}
    />
  </svg>
);
