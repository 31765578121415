export const SimcardIcon = ({ color, width, height }: { color?: string, width?: string, height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '14'}
    height={height || '17'}
    fill="none"
    viewBox="0 0 14 17"
  >
    <g
      stroke={color || '#363BC4'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
    >
      <path d="M1 4.6c0-1.26 0-1.89.245-2.371a2.25 2.25 0 01.984-.984C2.709 1 3.339 1 4.6 1h2.409c.55 0 .825 0 1.084.062.23.055.45.146.65.27.228.139.422.333.811.722l2.392 2.392c.389.389.583.583.722.81.124.202.215.421.27.65.062.26.062.535.062 1.085V12.4c0 1.26 0 1.89-.245 2.371-.216.424-.56.768-.984.984-.48.245-1.11.245-2.371.245H4.6c-1.26 0-1.89 0-2.371-.245a2.25 2.25 0 01-.984-.984C1 14.291 1 13.662 1 12.4V4.6z" />
      <path d="M4 9.85c0-.21 0-.315.04-.395a.375.375 0 01.165-.164c.08-.041.185-.041.395-.041h4.8c.21 0 .315 0 .395.04.07.037.128.094.164.165.041.08.041.185.041.395v2.55c0 .21 0 .315-.04.395a.375.375 0 01-.165.164C9.715 13 9.61 13 9.4 13H4.6c-.21 0-.315 0-.395-.04a.375.375 0 01-.164-.165C4 12.715 4 12.61 4 12.4V9.85z" />
    </g>
  </svg>
);
