type UtilityIconProps = {
  color?: string;
}

export const UtilityIcon = ({ color = '#363BC4', ...props }: UtilityIconProps): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 5.86851L2.4447 9.55039C1.96321 9.86951 1.72246 10.0291 1.63908 10.2314C1.56621 10.4083 1.56621 10.6075 1.63908 10.7844M8 5.86851L13.5553 9.55039C14.0368 9.86951 14.2775 10.0291 14.3609 10.2314C14.4338 10.4083 14.4338 10.6075 14.3609 10.7844M8 5.86851V1.22911M8 10.151L2.4447 6.46914C1.96321 6.15002 1.72246 5.99046 1.63908 5.78811C1.56621 5.61128 1.56621 5.41198 1.63908 5.23515M8 10.151L13.5553 6.46914C14.0368 6.15002 14.2775 5.99046 14.3609 5.78811C14.4338 5.61128 14.4338 5.41198 14.3609 5.23515M8 10.151V14.7904M14.4904 10.8457L8.61039 14.7427C8.38945 14.8892 8.27898 14.9624 8.15996 14.9909C8.05475 15.0161 7.94525 15.0161 7.84004 14.9909C7.72102 14.9624 7.61055 14.8892 7.38961 14.7427L1.50961 10.8457C1.32341 10.7222 1.23031 10.6605 1.16285 10.5782C1.10314 10.5054 1.05829 10.4211 1.03092 10.3303C1 10.2278 1 10.1146 1 9.88815V6.13138C1 5.90493 1 5.79171 1.03092 5.68919C1.05829 5.59843 1.10314 5.51416 1.16285 5.4413C1.23031 5.35899 1.32341 5.29728 1.50961 5.17387L7.38961 1.27678C7.61055 1.13035 7.72102 1.05714 7.84004 1.02865C7.94525 1.00347 8.05475 1.00347 8.15996 1.02865C8.27898 1.05714 8.38945 1.13035 8.61039 1.27678L14.4904 5.17387C14.6766 5.29728 14.7697 5.35899 14.8371 5.4413C14.8969 5.51416 14.9417 5.59843 14.9691 5.68919C15 5.79171 15 5.90493 15 6.13138V9.88815C15 10.1146 15 10.2278 14.9691 10.3303C14.9417 10.4211 14.8969 10.5054 14.8371 10.5782C14.7697 10.6605 14.6766 10.7222 14.4904 10.8457Z" stroke={color} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
