type PressureIconProps = {
  color?: string;
}

export const PressureIcon = ({ color, ...props }: PressureIconProps): JSX.Element => (
  <svg {...props} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 13C8.34 13 7 11.66 7 10C7 8.88 7.61 7.9 8.5 7.39L18.21 1.77L12.68 11.35C12.18 12.33 11.17 13 10 13ZM10 0C11.81 0 13.5 0.5 14.97 1.32L12.87 2.53C12 2.19 11 2 10 2C5.58 2 2 5.58 2 10C2 12.21 2.89 14.21 4.34 15.65H4.35C4.74 16.04 4.74 16.67 4.35 17.06C3.96 17.45 3.32 17.45 2.93 17.07C1.12 15.26 0 12.76 0 10C0 4.48 4.48 0 10 0ZM20 10C20 12.76 18.88 15.26 17.07 17.07C16.68 17.45 16.05 17.45 15.66 17.06C15.27 16.67 15.27 16.04 15.66 15.65C17.11 14.2 18 12.21 18 10C18 9 17.81 8 17.46 7.1L18.67 5C19.5 6.5 20 8.18 20 10Z"
      fill={color || '#5D5D5D'}
    />
  </svg>
);
