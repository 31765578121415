type SuperHeatingIconProps = {
  color?: string;
}

export const SuperHeatingIcon = ({ color, ...props }: SuperHeatingIconProps): JSX.Element => (
  <svg {...props} width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.57 13.16C6.21 13.44 5.4 14.32 5.4 15.57C5.4 16.91 6.51 17.99 7.89 17.99C9.94 17.99 11.6 16.33 11.6 14.28C11.6 13.21 11.45 12.16 11.14 11.16C10.35 12.23 8.94 12.88 7.57 13.16ZM9.5 0.669998C9.5 0.669998 10.24 3.32 10.24 5.47C10.24 7.53 8.89 9.2 6.83 9.2C4.76 9.2 3.2 7.53 3.2 5.47L3.23 5.11C1.21 7.51 0 10.62 0 14C0 18.42 3.58 22 8 22C12.42 22 16 18.42 16 14C16 8.61 13.41 3.8 9.5 0.669998ZM8 20C4.69 20 2 17.31 2 14C2 12.47 2.3 10.96 2.86 9.57C3.87 10.58 5.27 11.2 6.83 11.2C9.49 11.2 11.58 9.37 12.11 6.77C13.34 8.97 14 11.44 14 14C14 17.31 11.31 20 8 20Z"
      fill={color || '#5d5d5d'}
    />
  </svg>
);
