type CloseEyeIconProps = {
    color?: string;
  }

export const CloseEyeIcon = ({ color = '#202370', ...props }: CloseEyeIconProps): JSX.Element => (
  <svg {...props} width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0304 13.103C5.84398 13.103 2.32697 9.96315 0.60415 8.09081C0.215803 7.67228 0 7.12243 0 6.55149C0 5.98054 0.215803 5.43069 0.60415 5.01216C2.32697 3.13982 5.84398 0 10.0304 0C14.2168 0 17.7644 3.167 19.494 5.05633C19.8697 5.46363 20.0783 5.99741 20.0783 6.55149C20.0783 7.10556 19.8697 7.63934 19.494 8.04664C17.7644 9.93597 14.2406 13.103 10.0304 13.103ZM1.85464 6.1641C1.75646 6.2692 1.70185 6.40766 1.70185 6.55149C1.70185 6.69531 1.75646 6.83377 1.85464 6.93887C3.09154 8.2811 6.36049 11.4039 10.0304 11.4039C13.7003 11.4039 16.9897 8.25392 18.2402 6.89809C18.3271 6.80291 18.3753 6.67868 18.3753 6.54979C18.3753 6.42089 18.3271 6.29666 18.2402 6.20148C16.9897 4.84905 13.7207 1.69904 10.0304 1.69904C6.3401 1.69904 3.09154 4.82187 1.85464 6.1641Z"
      fill={color}
    />
  </svg>
);
