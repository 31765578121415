export const LogoutIcon = (): JSX.Element => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.16667 11.6189C3.16667 11.8756 3.16667 12.004 3.17798 12.1156C3.27193 13.0423 3.9341 13.8077 4.82926 14.0244C4.93708 14.0505 5.06286 14.0675 5.31433 14.1014L10.0752 14.7438C11.4304 14.9267 12.108 15.0181 12.6339 14.8065C13.0956 14.6207 13.4802 14.2799 13.7232 13.8413C14 13.3417 14 12.6499 14 11.2662V4.66236C14 3.2787 14 2.58687 13.7232 2.08727C13.4802 1.64864 13.0956 1.30786 12.6339 1.12206C12.108 0.910431 11.4304 1.00187 10.0752 1.18473L5.31433 1.82715C5.06283 1.86109 4.93709 1.87806 4.82926 1.90416C3.9341 2.12087 3.27193 2.8863 3.17798 3.81293C3.16667 3.92455 3.16667 4.05293 3.16667 4.30971M7.5 5.04062L10.3889 7.96429M10.3889 7.96429L7.5 10.888M10.3889 7.96429H1"
      stroke="#686868"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
