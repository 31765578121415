type StatisticsIconProps = {
  color?: string;
}

export const StatisticsIcon = ({ color = 'white' }: StatisticsIconProps): JSX.Element => (
  <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.59174 10.8375H6.34253C6.15025 10.8375 5.99454 10.9932 5.99454 11.1855V16.2303C5.99454 16.4226 6.15025 16.5783 6.34253 16.5783H9.59174C9.78402 16.5783 9.9395 16.4226 9.9395 16.2303V11.1852C9.9395 10.993 9.78379 10.8375 9.59174 10.8375ZM14.658 0.421936H11.4087C11.2165 0.421936 11.0608 0.577646 11.0608 0.769926V16.2301C11.0608 16.4223 11.2165 16.5781 11.4087 16.5781H14.658C14.8502 16.5781 15.0059 16.4223 15.0059 16.2301V0.769926C15.0059 0.577646 14.8502 0.421936 14.658 0.421936V0.421936ZM19.7502 5.11877H16.5009C16.3087 5.11877 16.153 5.27448 16.153 5.46676V16.2301C16.153 16.4223 16.3087 16.5781 16.5009 16.5781H19.7499C19.9422 16.5781 20.0979 16.4223 20.0979 16.2301V5.46676C20.0979 5.27471 19.9422 5.11877 19.7502 5.11877V5.11877ZM4.49954 5.11877H1.25033C1.05805 5.11877 0.902344 5.27448 0.902344 5.46676V16.2301C0.902344 16.4223 1.05805 16.5781 1.25033 16.5781H4.49954C4.69182 16.5781 4.84753 16.4223 4.84753 16.2301V5.46676C4.84753 5.27471 4.69182 5.11877 4.49954 5.11877V5.11877Z"
      fill={color}
    />
  </svg>
);
