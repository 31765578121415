import styled from 'styled-components';

import { DottedLineIcon, LineIcon } from '~/icons';
import { colors } from '~/styles/colors';

export const GraphWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

export const ModalMobile = styled.div<{ isModalOpen }>(
  ({ isModalOpen }) => `
  display:${isModalOpen ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.White};
  width: 100%;
  height: 100vh;
  z-index: 1;
  overflow: hidden;
  transition: all .5s ease-in-out;

  @media (min-width: 768px) {
    display: none;
  }
`,
);

export const ColoredDottedLine = styled(DottedLineIcon)(
  ({ color }) => `
  margin-left: 10px;
  color: ${color}
`,
);

export const ColoredLine = styled(LineIcon)(
  ({ color }) => `
  margin-left: 10px;
  color: ${color}
`,
);

export const CheckboxLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Text = styled.span`
  font-weight: normal;
  font-size: 1em;
  line-height: 26px;
  color: ${colors.Grey400};
`;

export const ModalSection = styled.div`
  width: 100%;
  height: 80px;
  background: ${colors.Grey030};
  border-bottom: 2px solid ${colors.Grey100};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
  svg {
    cursor: pointer;
  }
`;

export const ModalTitle = styled.span`
  font-weight: bold;
  font-size: 1.25em;
  line-height: 27px;
  color: ${colors.Grey400};
`;

export const MobileWrapper = styled.div`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const CardWrapper = styled.div`
  padding: 32px 24px;
  margin-top: 24px;
  background: ${colors.White};
  border-radius: 16px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24), 0px 3px 12px rgba(0, 0, 0, 0.12);
`;

export const CustomLabel = styled.text<{ angle }>`
transform: rotate(${({ angle }) => (angle || -90)}deg);
`;
