type LayersIconProps = {
  color?: string;
}

export const LayersIcon = ({ color = '#363BC4', ...props }: LayersIconProps): JSX.Element => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M8.31756 11.0076H7.77491L0.449151 7.75168C0.361662 7.72027 0.281561 7.67122 0.21382 7.60757C0.146079 7.54392 0.0921443 7.46702 0.0553626 7.38165C0.018581 7.29628 -0.000262156 7.20427 2.75489e-06 7.11131C0.000267666 7.01836 0.019635 6.92645 0.0569026 6.8413C0.0941702 6.75614 0.148543 6.67955 0.216645 6.61629C0.284748 6.55302 0.365127 6.50443 0.452793 6.47352C0.54046 6.44262 0.633543 6.43006 0.726265 6.43663C0.818986 6.44321 0.909368 6.46876 0.9918 6.51172L8.04623 9.64552L15.0925 6.51172C15.2544 6.45364 15.4322 6.45917 15.5901 6.5272C15.748 6.59524 15.8742 6.72069 15.9431 6.8782C16.012 7.03571 16.0186 7.21352 15.9614 7.37567C15.9043 7.53782 15.7876 7.67222 15.6352 7.75168L8.31756 11.0076Z" fill={color} />
    <path d="M8.31763 7.75174H7.77498L0.449219 4.49585V3.25589L7.77498 0H8.31763L15.6434 3.25589V4.49585L8.31763 7.75174ZM2.38647 3.87451L8.04088 6.38969L13.698 3.87451L8.04088 1.35662L2.38647 3.87451Z" fill={color} />
    <path d="M8.31746 14.2635H7.77481L0.449051 11.0076C0.296582 10.9282 0.179977 10.7938 0.122815 10.6316C0.0656529 10.4695 0.0721996 10.2917 0.141131 10.1341C0.210063 9.97663 0.336236 9.85118 0.494135 9.78315C0.652034 9.71511 0.829877 9.70958 0.9917 9.76767L8.04613 12.9015L15.0924 9.76767C15.2543 9.70958 15.4321 9.71511 15.59 9.78315C15.7479 9.85118 15.8741 9.97663 15.943 10.1341C16.0119 10.2917 16.0185 10.4695 15.9613 10.6316C15.9042 10.7938 15.7875 10.9282 15.6351 11.0076L8.31746 14.2635Z" fill={color} />
  </svg>
);
