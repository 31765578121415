type KwhIconProps = {
  color?: string;
};

export const KwhIcon = ({ color }: KwhIconProps): JSX.Element => (
  <svg
    width="159"
    height="150"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M844.7 532.9v-43.5h46.8v43.5zM500.9 990C237.1 990 22.5 775.2 22.5 511.2c0-244 183.4-445.5 419.3-474.8l-25.1 90.5c-66.5 15.6-126.6 49.2-175.8 94.2l33.6 33.6-30.7 30.7-33.5-33.4c-58.9 66.1-96.8 150.1-102.3 237.5h43.8V533h-43.7c5 91.7 41.5 175 98.7 239.6l31.5-31.5 30.7 30.7-31.5 31.5c69.8 63.3 162.1 102.1 263.5 102.1 100.7 0 192.1-38.4 261.4-100.9l-32.7-32.7 30.7-30.7 32.6 32.6c62.1-69.8 100.1-161.7 100.1-262.6 0-101-38.1-193.1-100.4-262.9l-34.4 34.5-30.7-30.7 34.6-34.7c-49.6-44.6-110.5-76.7-178-91.4l-28.7-90.2c237.9 27.3 422 230 422 475.5 0 264-212.8 478.8-476.6 478.8zM107 511c0 .1 0 .1 0 0v.2zm414.7 392.9h-43.4v-46.2h43.4zm85.315-440.804c-59.4 59.4-155.6 59.4-214.9 0-39.9-39.9-53-96.5-39.3-147.3C382.215 221.296 435.5 292.8 481.7 43.4c2.1-11.4 1.9-7.7 4.3-17.3 5.5-22.1 23.2-21.1 28.2.5 2.3 10 2.4 5.4 4.5 17.4 45.2 248.3 102.815 176.996 127.915 273.096 13.2 50.5 0 106.5-39.6 146zm-106.9-217.5c-60.2 0-108.9 48.8-108.9 109s48.7 109 108.9 109c60.1 0 108.9-48.8 108.9-109 .1-60.3-48.7-109-108.9-109zm-.1 173c-35.4 0-64.1-28.7-64.1-64.2s28.7-64.2 64.1-64.2c35.4 0 64.1 28.7 64.1 64.2s-28.7 64.2-64.1 64.2z"
        fill={color}
      />
    </g>
  </svg>
);
