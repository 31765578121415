type ProgIconProps = {
  size?: string
  color?: string
  cursor?: string
}

export const ProgIcon = ({ size = '19px', color = '#363BC4', ...props }: ProgIconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={props}>
    <path d="M7.505 15.01C6.02065 15.01 4.56964 14.5698 3.33545 13.7452C2.10126 12.9205 1.13932 11.7484 0.571287 10.377C0.00325179 9.00568 -0.145372 7.49668 0.14421 6.04085C0.433792 4.58502 1.14857 3.24776 2.19817 2.19817C3.24776 1.14857 4.58502 0.433792 6.04085 0.14421C7.49668 -0.145372 9.00568 0.00325179 10.377 0.571287C11.7484 1.13932 12.9205 2.10126 13.7452 3.33545C14.5698 4.56964 15.01 6.02065 15.01 7.505C15.008 9.49483 14.2166 11.4026 12.8096 12.8096C11.4026 14.2166 9.49483 15.008 7.505 15.01V15.01ZM7.505 1.27204C6.27224 1.27204 5.06716 1.63759 4.04215 2.32248C3.01715 3.00737 2.21825 3.98082 1.74649 5.11975C1.27473 6.25867 1.1513 7.51192 1.3918 8.72099C1.6323 9.93007 2.22593 11.0407 3.09763 11.9124C3.96933 12.7841 5.07993 13.3777 6.28901 13.6182C7.49809 13.8587 8.75133 13.7353 9.89025 13.2635C11.0292 12.7918 12.0026 11.9929 12.6875 10.9679C13.3724 9.94284 13.738 8.73777 13.738 7.505C13.7359 5.85254 13.0786 4.26833 11.9101 3.09986C10.7417 1.93139 9.15747 1.27406 7.505 1.27204V1.27204Z" fill={color} />
    <path d="M9.46061 10.5956C9.33606 10.5966 9.21338 10.5679 9.10464 10.5124L6.95501 9.37715C6.85236 9.32005 6.76765 9.23961 6.70913 9.14367C6.65062 9.04773 6.62029 8.93957 6.62111 8.82973V5.04571C6.62111 4.87844 6.69379 4.71803 6.82317 4.59976C6.95254 4.48148 7.12801 4.41504 7.31098 4.41504C7.49394 4.41504 7.66941 4.48148 7.79879 4.59976C7.92817 4.71803 8.00085 4.87844 8.00085 5.04571V8.46646L9.80555 9.41751C9.93775 9.48665 10.0412 9.59384 10.0998 9.72236C10.1584 9.85088 10.1688 9.99351 10.1294 10.128C10.09 10.2625 10.0031 10.3813 9.88208 10.4659C9.76111 10.5505 9.61291 10.5961 9.46061 10.5956V10.5956Z" fill={color} />
  </svg>

);
