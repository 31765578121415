type AlertIconProps = {
  color?: string;
}

export const AlertIcon = ({ color, ...props }: AlertIconProps): JSX.Element => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8781 16.4695C17.9502 14.7855 16.9729 12.7924 16.9729 10.5455V7.27491C16.9907 4.78145 15.0831 3.12764 13.4439 2.46909C12.9818 2.28364 12.4948 2.14618 11.9984 2.04073V1.81818C11.9984 0.815636 11.0704 0 9.92974 0C8.78911 0 7.86114 0.815636 7.86114 1.81818V2.07491C7.41473 2.17782 6.97619 2.30364 6.55957 2.47236C4.74788 3.20618 2.91261 4.97491 2.89606 7.27273V10.5455C2.89606 12.8684 1.96271 14.8615 0.122057 16.4695C0.0124209 16.5655 -0.0272964 16.7069 0.0190404 16.8367C0.0653772 16.9662 0.190735 17.0625 0.343398 17.0858L4.27003 17.6818C5.01059 17.7942 5.68289 17.884 6.31547 17.9575C7.03742 19.2015 8.45524 20 10.005 20C11.5553 20 12.9735 19.2007 13.695 17.956C14.3247 17.8829 14.9937 17.7935 15.7301 17.6815L19.6563 17.0855C19.809 17.0625 19.934 16.9662 19.9807 16.8364C20.0275 16.7069 19.9877 16.5655 19.8781 16.4695ZM8.68817 1.81818C8.68817 1.21673 9.24504 0.727273 9.92933 0.727273C10.6136 0.727273 11.1705 1.21673 11.1705 1.81818V1.88836C10.346 1.78582 9.50568 1.79164 8.68817 1.90655V1.81818ZM5.37839 7.63636H5.37591C5.14754 7.63491 4.96343 7.47127 4.96467 7.27055C4.97708 5.568 6.51695 4.49127 7.42549 4.12364C9.0179 3.47818 10.981 3.47927 12.5842 4.12291C12.7919 4.20618 12.8833 4.42182 12.7886 4.60473C12.6934 4.78727 12.4481 4.868 12.24 4.784C10.8528 4.22655 9.14988 4.22509 7.77219 4.784C7.04114 5.08 5.80204 5.93782 5.79253 7.27491C5.79087 7.47527 5.60594 7.63636 5.37839 7.63636ZM12.6624 18.0647C12.0339 18.8142 11.0538 19.2727 10.005 19.2727C8.95667 19.2727 7.97698 18.8145 7.34854 18.0655C7.51237 18.0804 7.67165 18.092 7.83052 18.104C7.90623 18.1098 7.98401 18.1167 8.0589 18.1222C8.11682 18.1262 8.17267 18.1287 8.23018 18.1324C9.43245 18.2095 10.5396 18.2095 11.741 18.1342C11.8101 18.1298 11.8775 18.1265 11.9475 18.1218C12.0377 18.1156 12.1312 18.1073 12.2226 18.1004C12.3628 18.0895 12.5023 18.0793 12.6467 18.0662C12.6516 18.0655 12.657 18.0651 12.6624 18.0647Z"
      fill={color}
    />
  </svg>
);
